<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
        
        <!--BODY-->
        <form @submit.prevent="editarPost()" class="main-section ml-4 mr-4  mt-28 rounded-2xl bg-white w-full md:ml-80 md:mt-28 md:mr-6  py-4 px-4 md:px-10 md:py-10 overflow-auto" style="min-height: 84vh;max-height: 84vh">
            <!--Encabezado-->
           <section id="encabezadoMenu" class="grid grid-cols-1 md:grid-cols-12 items-center md:mb-12">
                <!--Atras-->
                <RouterLink :to="'/blog'"  class="hidden md:flex col-span-2 bg-primary rounded-xl h-12 items-center justify-center gap-x-3 md:w-32">
                   <svg xmlns="http://www.w3.org/2000/svg" width="12.217" height="17.767" viewBox="0 0 12.217 17.767">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.934,15.075,23.02,8.358a1.12,1.12,0,0,0,0-1.793,1.762,1.762,0,0,0-2.163,0L11.7,14.176a1.119,1.119,0,0,0-.045,1.751l9.2,7.664a1.765,1.765,0,0,0,2.163,0,1.12,1.12,0,0,0,0-1.793Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                    </svg>
                    <span class="poppins-regular text-base text-white">Regresar</span>
                </RouterLink>  
                
                <!--Titulo BLOG-->
                <div id="contentTitulo" class="justify-self-center md:col-span-8" >
                    <h1 class="poppins-bold text-2xl color-primary text-center">Blog</h1>
                    <p class="poppins-regular text-center">Post > <span class="poppins-semibold">{{post.titulo}}</span></p>
                </div>

                <!--Acciones-->
                <div class="hidden md:flex flex-row gap-x-2  justify-end col-span-2">
                    <button type="submit" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                        <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                        </svg>
                    </button>
                    <a  class="bg-blue-500 w-12 h-12 px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                        <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                        </svg>
                    </a>
                    <button v-on:click="eliminarPost()" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                        </svg>
                    </button>
                </div>

            </section>

            <!--Acciones en Mobile-->
            <section class="md:hidden grid grid-cols-8 mt-8 mb-9">
                 <RouterLink :to="'/Portafolio'" class=" md:hidden col-span-3 bg-primary rounded-xl h-12 flex items-center justify-center gap-x-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="12.217" height="17.767" viewBox="0 0 12.217 17.767">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.934,15.075,23.02,8.358a1.12,1.12,0,0,0,0-1.793,1.762,1.762,0,0,0-2.163,0L11.7,14.176a1.119,1.119,0,0,0-.045,1.751l9.2,7.664a1.765,1.765,0,0,0,2.163,0,1.12,1.12,0,0,0,0-1.793Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                    </svg>
                    <span class="poppins-regular text-base text-white">Regresar</span>
                </RouterLink>  
                
                <!--Acciones-->
                <div class="flex flex-row gap-x-2  justify-end col-span-5">
                    <button type="submit" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                        <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                        </svg>
                    </button>

                    <a  class="bg-blue-500 w-12 h-12 px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                        <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                        </svg>

                    </a>
                    <button  v-on:click="eliminarPost()" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                        </svg>
                    </button>
                </div>
            </section>

            <!--Campos Informacion general -  galeria  - fotos -->
            <section class="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-x-6">
                <!--Estado-->
                <div class="border border-color-primary rounded-2xl px-3 py-6 md:order-3 md:col-span-1 md:h-32">
                    <div class="w-full flex flex-col gap-y-2">
                        <label class="poppins-medium text-base">Estado:</label>
                        <select class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="post.borrador">
                            <option value="true">Borrador</option>
                            <option value="false">Publicado</option>
                        </select>
                    </div>
                </div>

                <!--Fotos Principal y Secundaria-->
                <div class=" grid grid-cols-2 gap-x-3 md:order-1 md:col-span-1 md:flex md:flex-col md:gap-y-6">
                    <div class="w-full flex flex-col gap-y-2 border border-color-primary rounded-2xl px-3 py-3 md:p-6 ">
                        <label class="poppins-medium text-base text-center md:text-xl mb-4">Foto</label>
                        <div class="relative w-full  h-32 md:h-36 border-2 border-color-primary rounded-xl">
                            <img id="imageOne"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="post.imagen"/>
                        </div>
                       <!--Subir foto-->
                        <div class="flex justify-center items-center md:mt-3">
                            <button   v-on:click="clickSubirFotoOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                            <input id="inputFotoOne" v-on:change="previaFotoOne($event)"  type="file" class="hidden"/>
                        </div>
                    </div>
                </div>

                <!--Informacion General-->
                <div class="border border-color-primary rounded-2xl px-3 py-6 flex flex-col gap-y-3 md:order-2 md:col-span-2">
                    <h2 class="poppins-medium text-xl mb-3">Información General</h2>
                    
                    <!--Nombre del proyecto-->
                    <div class="w-full flex flex-col gap-y-2">
                        <label class="poppins-medium text-base">Nombre:</label>
                        <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="post.titulo">
                    </div>
                    
                    <!--Direccion - Tipo Proyecto - Fecha Elabaoracion-->
                    <div class="grid grid-cols-4 gap-y-3 gap-x-3 md:grid-cols-3">
                       
                        <!--CATEGORIA-->
                         <div class="w-full flex flex-col gap-y-2 col-span-2 md:col-span-1">
                            <label class="poppins-medium text-base">Categoria:</label>
                            <select class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="post.categoria._id">
                                <option v-for="categoria in categorias" :key="categoria" :value="categoria._id">{{categoria.nombre}}</option>
                            </select>
                        </div>
                        <!--Fecha Elaboracion -->
                        <div class="w-full flex flex-col gap-y-2 col-span-2 md:col-span-1">
                            <label class="poppins-medium text-base">Fecha Elaboración:</label>
                            <input type="date" class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="post.fecha">
                        </div>
                         <!--Autor-->
                         <div class="w-full flex flex-col gap-y-2 col-span-4 md:col-span-1">
                            <label class="poppins-medium text-base">Slug:</label>
                            <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="post.slug">
                        </div>
                    </div>
                    <!--Descripcion-->
                    <div>
                        <label class="poppins-medium text-base">Descripción:</label>
                        <ckeditor :editor="editorDescripcion" v-model="post.descripcion" :config="editorConfig"></ckeditor>
                    </div>
                </div>
            </section>

           
        </form>
    </div> 
  
</template>

<script>
    import axios from 'axios'
    import router from "../../router"  
    import Menu from '../../components/Menu';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import moment from 'moment';
    import Swal from 'sweetalert2'


    /*Services */
    import FileServices from '../../services/fileService.js';


    export default {
        name: "EditarPost",
        components: {Menu, ckeditor: CKEditor.component},
        data(){
            
            return {
                editorDescripcion: ClassicEditor,
                 editorConfig: {
                    // The configuration of the editor.
                    toolbar: {
                        items:[]
                    }
                },
                usuario:{
                    username:'',
                    password:''
                },
                post:{
                    titulo:'',
                    categoria:"63641683f6009b2ae546f74f",//defecto sin categoria
                    descripcion:"",
                    imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    fecha:"",
                    slug:"",
                    estado:true,
                    borrador:true,
                    modoEditar:false,
                    modoCrear:false,
                },
                categorias: [],
                imgFileFotoOne:'',
                APIURL: "/api"
            }
        },
        mounted(){
            //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
            if(sessionStorage.getItem("rjm_token")===null){
                router.push("/")
            }
            this.getPostID()
            this.getCategoriasAll()
        },
        methods:{
            getPostID(){
                var id_post = this.$route.params.id
                axios.get(this.APIURL+"/blog/post/"+id_post).then((post) => {
                    console.log(post)
                    if(post.data===undefined){
                        this.$router.push("/blog/")
                    }
                    axios.get(this.APIURL+"/blog/categoria/"+post.data.categoria).then((categoria) => {
                        console.log(categoria)
                        this.post={
                            _id: post.data._id,
                            titulo: post.data.titulo,
                            categoria: categoria.data,
                            imagen:post.data.imagen,
                            descripcion: post.data.descripcion,
                            slug:post.data.slug,
                            fecha:moment(post.data.fecha).format("yyyy-MM-DD"),
                            estado: post.data.estado,
                            borrador: post.data.borrador
                        }
                        console.log(this.post)

                    })

                })
            },
            editarPost(){
                  var formData;
                this.post.categoria = this.post.categoria._id

                if(this.post.borrador==="true"){
                    this.post.borrador=true;
                }else{
                    this.post.borrador=false;
                }
                //convertir fecha a un formato
                this.post.fecha = moment(this.post.fecha).format("yyyy-MM-DD");
                
                //subir imagen del post
                formData = new FormData();
                formData.append('upload', this.imgFileFotoOne)
                FileServices.subirArchivos(formData).then(res=>{
                    if(res.location!=undefined){
                        if(res.size>0){
                            this.post.imagen = res.location;
                        }else{
                            this.post.imagen = "https://"+res.location;
                        }
                        
                        //actualizar
                        axios.put(this.APIURL+"/blog/post/"+this.post._id, this.post);
                    }else{
                        axios.put(this.APIURL+"/blog/post/"+this.post._id, this.post);
                    }
                    
                })

                Swal.fire({
                    title: "El Post se guardo exitosamente",
                    icon: 'success',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getPostID();
                    } 
                });
            },
            eliminarPost(){

                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            this.post.categoria = this.post.categoria._id
                            
                            if(this.post.borrador==="true"){
                                this.post.borrador=true;
                            }else{
                                this.post.borrador=false;
                            }

                            this.post.fecha = moment(this.post.fecha).format("yyyy-MM-DD"),

                            this.post.estado = false;

                            this.slug="";

                            console.log(this.post)

                            axios.put(this.APIURL+"/blog/post/"+this.post._id, this.post).then((response)=>{
                                if(response.status===200){
                                    Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.$router.push("/blog")
                                        } 
                                    });
                                }
                                
                            })
                            
                        }
                    });
            },
            getCategoriasAll(){
                axios.get(this.APIURL+"/blog/categoria/all").then((response) => {
                    this.categorias = response.data.categorias;
                })
            },

             clickSubirFotoOne(){
                document.getElementById("inputFotoOne").click()
            },
            previaFotoOne(event){
                
                const fileFotoOne = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFotoOne);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageOne").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(fileFotoOne)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileFotoOne = fileFotoOne
                
            }
            
            
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>