<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
       
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <!--Campo Titulo-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Nombre:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="nosotrosTemp.secciones.seccionFive.titulo" name="Valor"/>
                </div>
            </div>
        </div>
       </div>  
    </div>
</template>



<script>
  
    export default {
        name:"SectionFiveNosotros",
        props:['nosotros'],
        data(){
            return{
              
                nosotrosTemp:{
                    secciones:{
                        seccionFive:{titulo:""}
                    }
                }
            }

        },
        
        methods:{
            detectInput(){

                if(this.nosotrosTemp.secciones.seccionFive.titulo!=""){
                        console.log(this.nosotrosTemp)
                        this.$emit("nosotros", this.nosotrosTemp);
                    }
            },
        },
        watch: {
            nosotros: function() {
                this.nosotrosTemp = this.nosotros
                console.log(this.nosotrosTemp)
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
