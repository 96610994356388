<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
        <div class="flex flex-col gap-y-3 md:col-span-4">
             <!--Fondo Imagen-->
            <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
                <!--Campo Foto Fondo-->
                <div class="flex items-center flex-col gap-y-1 col-span-2 mb-1 md:col-span-3 md:row-span-3">
                    <label class="poppins-medium text-base  text-left w-full">Foto del Fondo:</label>
                    <div class=" w-full h-36 md:h-48  rounded-lg mb-2 md:mb-0">
                        <img id="imagenSeccionTwo" class="w-full h-full object-cover border-2 border-black rounded-lg" :src="nosotrosTemp.secciones.seccionTwo.imagen"/>
                    </div>
                    <!--Subir foto-->
                    <div class="flex justify-center items-center md:mt-3">
                        <button   v-on:click="clickSubirFotoSeccionTwoAbout()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputImagenSeccionTwo" v-on:change="previaImageSeccionTwoAbout($event)"  type="file" class="hidden"/>
                    </div>
                </div>
            </div>
        </div>
       
       <div class="flex flex-col gap-y-3  md:col-span-8">
         <!--Campo Titulo-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Nombre:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="nosotrosTemp.secciones.seccionTwo.titulo" name="Valor"/>
                </div>
            </div>
        </div>

        

         <!--Campo Descripcion-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Subtitulo</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Subitulo-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Nombre:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="nosotrosTemp.secciones.seccionTwo.subtitulo" name="Valor"/>
                </div>
            </div>
        </div>
       </div>  
    </div>
</template>



<script>
    export default {
        name:"SectionTwoNosotros",
        props:['nosotros'],
        data(){
            return{
                nosotrosTemp:{
                    secciones:{
                        seccionTwo:{imagen:"", titulo:"", subtitulo:""}
                    }
                },
                imgFileSeccionTwo: '',
            }

        },
        
        methods:{
            detectInput(){

                if(this.nosotrosTemp.secciones.seccionTwo.titulo!=""){
                        console.log(this.nosotrosTemp)
                        this.$emit("nosotros", this.nosotrosTemp);
                    }
            },


            clickSubirFotoSeccionTwoAbout(){
                document.getElementById("inputImagenSeccionTwo").click()
            },
            previaImageSeccionTwoAbout(event){
                
                const fileImagenFondo = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileImagenFondo);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imagenSeccionTwo").src = img;
                
                console.log(fileImagenFondo)

                this.imgFile = fileImagenFondo
               
               
                this.$emit("imgFileSeccionTwoAbout", this.imgFile);

                
            }
        },
        watch: {
            nosotros: function() {
                this.nosotrosTemp = this.nosotros
                console.log(this.nosotrosTemp)
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
