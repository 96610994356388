<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
        <div class="flex flex-col gap-y-3  md:col-span-12">
            <!--Campo Titulo-->
            <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
                <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
                <div class="flex flex-col gap-3 col-span-2" > 
                    <!--texto-->
                    <div class="flex flex-col gap-y-1 md:col-span-2">
                        <label class="poppins-medium text-sm">texto:</label>
                        <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="empresasTemp.seccionOneInfo.titulo" name="Valor"/>
                    </div>
                </div>
            </div>

            <!--Campo Descripcion-->
            <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
                <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Descripción:</h2> 
                <div class="flex flex-col gap-3 col-span-2" > 
                    <!--texto-->
                    <div class="flex flex-col gap-y-1 md:col-span-2">
                        <label class="poppins-medium text-sm">texto:</label>
                        <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="empresasTemp.seccionOneInfo.descripcion" name="Valor"/>
                    </div>
                </div>
            </div>

            <!--BOTON-->
            <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
                <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Botón:</h2> 
                <div class="flex flex-col gap-3 col-span-2" > 
                    <!--texto-->
                    <div class="flex flex-col gap-y-1 md:col-span-2">
                        <label class="poppins-medium text-sm">texto:</label>
                        <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="empresasTemp.seccionOneInfo.btn" name="Valor"/>
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>



<script>
   
   

    export default {
        name:"SectionOneInfoEmpresas",
        props:['empresas'],
        data(){
            return{
                empresasTemp:{
                    seccionOneInfo:{
                        titulo:""
                    }
                }
            }
        },
        methods:{
           
            detectInput(){
                if(this.empresasTemp.seccionOneInfo.titulo===''){
                    this.$emit("empresas", this.empresasTemp);
                }
            }
        },
        watch: {
            empresas: function() {
                this.empresasTemp = this.empresas
                console.log(this.empresas)
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    
</style>
