<template>
   
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
        <div class="flex flex-col gap-y-3 md:col-span-4">
             <!--Imagen One-->
             <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:p-6" >
                <!--Campo Imagen Fondo One-->
                <div class="flex items-center flex-col gap-y-1 col-span-2 mb-1 md:col-span-3 md:row-span-3">
                    <label class="poppins-medium text-base  text-center mb-1 w-full">Imagen 1:</label>
                    <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                        <img id="fondoImagenOne_img"  :src="homeTemp.portada.imagen_one" class="w-full h-full object-cover border-2 border-black rounded-lg"/>
                    </div>
                    <!--Subir Imagen One-->
                    <div class="flex justify-center items-center md:mt-3">
                        <button   v-on:click="clickSubirImagenOnePortadaHome()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputImagenOnePortadaHome" v-on:change="previaImagenOnePortadaHome($event)"  type="file" class="hidden"/>
                    </div>
                </div>
            </div>
             <!--Imagen Two-->
             <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:p-6" >
                <!--Campo Imagen Fondo two-->
                <div class="flex items-center flex-col gap-y-1 col-span-2 mb-1 md:col-span-3 md:row-span-3">
                    <label class="poppins-medium text-base  text-center mb-1 w-full">Imagen 2:</label>
                    <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                        <img id="fondoImagenTwo_img"  :src="homeTemp.portada.imagen_two" class="w-full h-full object-cover border-2 border-black rounded-lg"/>
                    </div>
                    <!--Subir Imagen two-->
                    <div class="flex justify-center items-center md:mt-3">
                        <button   v-on:click="clickSubirImagenTwoPortadaHome()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputImagenTwoPortadaHome" v-on:change="previaImagenTwoPortadaHome($event)"  type="file"  class="hidden"/>
                    </div>
                </div>
            </div>

            <!--Imagen Three-->
            <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:p-6" >
                <!--Campo Imagen Fondo three-->
                <div class="flex items-center flex-col gap-y-1 col-span-2 mb-1 md:col-span-3 md:row-span-3">
                    <label class="poppins-medium text-base  text-center mb-1 w-full">Imagen 3:</label>
                    <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                        <img id="fondoImagenThree_img"  :src="homeTemp.portada.imagen_three" class="w-full h-full object-cover border-2 border-black rounded-lg"/>
                    </div>
                    <!--Subir Imagen three-->
                    <div class="flex justify-center items-center md:mt-3">
                        <button   v-on:click="clickSubirImagenThreePortadaHome()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputImagenThreePortadaHome" v-on:change="previaImagenThreePortadaHome($event)"  type="file"  class="hidden"/><!--accept="video/mp4,video/x-m4v,video/*"-->
                    </div>
                </div>
            </div>

        </div>
       
       <div class="flex flex-col gap-y-3  md:col-span-8">
         <!--Campo Titulo-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.portada.titulo" name="Valor"/>
                </div>
            </div>
        </div>

         <!--Campo Descripcion-->
        <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Descripción</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Descripcion-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcion" v-model="homeTemp.portada.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
       </div>
       
    </div>

</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
    name:"Home-design",
    props:['home'],
    components: {ckeditor: CKEditor.component},
    data(){
        return{
            editorDescripcion: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
                toolbar: {
                    items:[]
                }
            },
            homeTemp:{
                portada:{
                    imagen_one:"",
                    imagen_two:"",
                    imagen_three:"",
                    titulo:"",
                    descripcion:""
                }
            },
            imgFileOnePortada: '',
            imgFileTwoPortada: '',
            imgFileThreePortada: '',


        }
    },
    methods:{
        clickSubirImagenOnePortadaHome(){
            document.getElementById("inputImagenOnePortadaHome").click()
        },
        previaImagenOnePortadaHome(event){
            
            const file = event.target.files[0];
        
            //Creamos una url temporal de nuestro imagen a traves del file
            let video = URL.createObjectURL(file);
            
            //Le agregamos la url temporal en el atributo src de la etiqueta img
            document.getElementById("fondoImagenOne_img").src = video;
            
            console.log(file)

            this.imgFileOnePortada = file
            
            
            this.$emit("imgFilePortadaOneHome", this.imgFileOnePortada);

            
        },
        clickSubirImagenTwoPortadaHome(){
            document.getElementById("inputImagenTwoPortadaHome").click()
        },
        previaImagenTwoPortadaHome(event){
            
            const file = event.target.files[0];
        
            //Creamos una url temporal de nuestro imagen a traves del file
            let video = URL.createObjectURL(file);
            
            //Le agregamos la url temporal en el atributo src de la etiqueta img
            document.getElementById("fondoImagenTwo_img").src = video;
            
            console.log(file)

            this.imgFileTwoPortada = file
            
            
            this.$emit("imgFilePortadaTwoHome", this.imgFileTwoPortada);

            
        },
        clickSubirImagenThreePortadaHome(){
            document.getElementById("inputImagenThreePortadaHome").click()
        },
        previaImagenThreePortadaHome(event){
            
            const file = event.target.files[0];
        
            //Creamos una url temporal de nuestro imagen a traves del file
            let video = URL.createObjectURL(file);
            
            //Le agregamos la url temporal en el atributo src de la etiqueta img
            document.getElementById("fondoImagenThree_img").src = video;
            
            console.log(file)

            this.imgFileThreePortada = file
            
            
            this.$emit("imgFilePortadaThreeHome", this.imgFileThreePortada);

            
        },
         detectInput(){

            if(this.homeTemp.portada.titulo!=""){
                    console.log(this.homeTemp)
                    this.$emit("home", this.homeTemp);
                }
        }
        
       
    },
    watch: {
        home: function() {
            this.homeTemp = this.home
            console.log(this.home)
        }
    }
}

</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>

