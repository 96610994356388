<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <!--Campo Titulo-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.area_practica.titulo" name="Valor"/>
                </div>
            </div>
        </div>

         <!--Campo Descripcion-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Descripción</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Descripcion-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcion" v-model="homeTemp.area_practica.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
        <!--Boton de accion-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Botón</h2> 
            <!--Campo Foto-->
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Descripcion-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.area_practica.btn" name="Valor"/>
                </div>
            </div>
        </div>
       </div>
    </div>
</template>



<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    export default {
        name:"AreaPracticaHome",
        props:['home'],
        components: {ckeditor: CKEditor.component},
        data(){
            return{
                editorDescripcion: ClassicEditor,
                editorConfig: {
                    // The configuration of the editor.
                    toolbar: {
                        items:[]
                    }
                },
                homeTemp:{
                    area_practica:{
                        titulo:"", 
                        descripcion:"", 
                        btn: ""
                    }
                }
            }

        },
        methods:{
            detectInput(){
                if(this.homeTemp.area_practica.titulo!=""){
                    console.log(this.homeTemp)
                    this.$emit("home", this.homeTemp);
                }
            }
        },
        watch: {
            home: function() {
                this.homeTemp = this.home
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
