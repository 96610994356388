import axios from 'axios'


class FileService {
    async subirArchivos(file)  {
        return await axios.post("/api/files/subir", file).then((response)=>{
            console.log(response.data)
            return response.data;
        })
    }
  }

  export default new FileService();