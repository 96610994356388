<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
        
        <!--BODY-->
        <div class="main-section ml-4 mr-4  mt-28 rounded-2xl bg-white w-full md:ml-80 md:mt-28 md:mr-6  py-6 px-6 md:px-10 md:py-10 overflow-auto" style="min-height: 82vh">
           <!--Encabezado-->
           <section id="encabezadoArea" class="grid grid-cols-5 items-center ">
                <!--Search-->
                <div id="contentSearch" class="md:col-span-1">
                    <div class="flex flex-row">
                        <div class="hidden border-2 md:rounded-r-none border-color-primary rounded-lg md:flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>

                        <div id="btnSearch" v-on:click="showSearchMobile()" class="md:hidden border-2 md:rounded-r-none border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>
                        
                        <input id="inputBuscador" v-on:keyup="buscarArea($event)" class="hidden md:block md:border-l-0 md:rounded-l-none text-gray-400 border-2 border-color-primary rounded-lg h-12 w-full px-3" placeholder="Buscar Correo Electrónico"/>
                    </div>
                </div>
                <!--Titulo Area-->
                <div id="contentTitulo" class="justify-self-center col-span-3">
                    <h1 class="poppins-bold text-lg md:text-2xl leading-5 color-primary text-center">Áreas de Práctica</h1>
                </div>
                 <!--Add Area-->
                <div  id="contentAddArea" class="justify-self-end">
                    <div v-on:click="crearArea()" class="cursor-pointer bg-primary border-2 border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25.554" height="25.554" viewBox="0 0 25.554 25.554">
                        <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M32.928,20.151h-9.6v-9.6a1.591,1.591,0,1,0-3.182,0v9.6h-9.6a1.591,1.591,0,0,0,0,3.182h9.6v9.6a1.591,1.591,0,1,0,3.182,0v-9.6h9.6a1.591,1.591,0,1,0,0-3.182Z" transform="translate(-8.965 -8.965)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
            </section>

            <!--Listado de Areas-->
            <section id="vistaAreas" class="mt-6 relative"  style="max-height: 65vh;">
                <div id="contentAreas" class="grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-y-3 overflow-auto">
                    <div v-for="area in areas" :key="area._id" class="grid grid-cols-1 border-2 border-color-primary py-3 pb-4 px-3 rounded-lg">
                        <div class="w-full h-56">
                            <img class="w-full h-full object-cover rounded-lg " :src="area.imagenPrincipal"/>
                        </div>
                        <div class="col-span-4 row-span-2 flex flex-col justify-between">
                            <div class="text-center py-3 pt-6 ">
                                <h1 class="poppins-semibold  text-lg leading-5 truncate">{{area.nombre}}</h1>
                                <p class="text-sm text-yellow-500" v-if="area.borrador">Borrador</p>
                                <p class="text-sm text-green-600" v-if="!area.borrador">Publicado</p>

                            </div>
                           
                            <!--Acciones-->
                            <div class="flex flex-row gap-x-2  justify-center">
                                <RouterLink :to="'/area-practica/'+area._id" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>

                                <a  class="bg-blue-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.28" height="15.187" viewBox="0 0 30.28 20.187">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M30.1,13.826A16.861,16.861,0,0,0,15.14,4.5,16.864,16.864,0,0,0,.183,13.826a1.7,1.7,0,0,0,0,1.535A16.861,16.861,0,0,0,15.14,24.687,16.864,16.864,0,0,0,30.1,15.36,1.7,1.7,0,0,0,30.1,13.826ZM15.14,22.163a7.57,7.57,0,1,1,7.57-7.57A7.57,7.57,0,0,1,15.14,22.163Zm0-12.617a5.01,5.01,0,0,0-1.331.2,2.515,2.515,0,0,1-3.517,3.517A5.035,5.035,0,1,0,15.14,9.547Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                 <button v-on:click="eliminarArea(area)" class="bg-red-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

               <!--------------------------------
                -         Paginacion
                ---------------------------------->
                <section id="paginacionAreas" class="mt-6 pb-6 flex flex-row gap-2 justify-center   bottom-12 left-0 md:left-80 right-0">
                <span v-on:click="prevPage()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="16.227" viewBox="0 0 9.277 16.227">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.047,14.3l6.14-6.135a1.16,1.16,0,1,0-1.642-1.638l-6.956,6.951a1.157,1.157,0,0,0-.034,1.6l6.985,7a1.16,1.16,0,0,0,1.642-1.638Z" transform="translate(-11.251 -6.194)"/>
                    </svg>
                </span>

                <ul class="flex flex-row gap-2">
                    <li class=" p-2 flex items-center justify-center rounded-lg w-8 h-8" :class="(page === paginacionArea.page) ? 'bg-primary text-white': 'border border-color-primary'">{{page}}</li>
                </ul>

                <span  v-on:click="nextPage()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.28" height="16.23" viewBox="0 0 9.28 16.23">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M17.729,14.308,11.587,8.171a1.155,1.155,0,0,1,0-1.638,1.17,1.17,0,0,1,1.643,0l6.958,6.953a1.158,1.158,0,0,1,.034,1.6l-6.987,7a1.16,1.16,0,1,1-1.643-1.638Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                </span>
                </section>
                
            </section>
           
        </div>
    </div> 
  
</template>

<script>
'use strict'
    import axios from 'axios'
    import router from "../../router"  
    import Menu from '../../components/Menu';
    import Swal from 'sweetalert2'
    const jwt = require('jsonwebtoken');

    export default {
        name: "Area",
        components: {Menu},
        data(){
            return {
                areas:[],
                area:{
                    imagenPrincipal: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    imagenSecundaria: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    nombre: "Área de Practica Borrador",
                    descripcion:"",
                    borrador:true,
                    estado:true
                },
                paginacionArea:'',
                page:1,
                valueSearch: false, /*Se usa para mostrar y desaparecer el buscador en mobile */
            }
        },
        mounted(){
            //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
            /**Verificar si esta logeado en el sistema */
            if(sessionStorage.getItem("rjm_token")===null ||sessionStorage.getItem("rjm_token")==='undefined' ){
                this.$router.push({ path: '/'})
            }
            this.decoded = jwt.decode(sessionStorage.getItem("rjm_token"));
            console.log(this.decoded)
            if(this.decoded!=null){
                if(this.decoded.exp<=1){
                sessionStorage.removeItem("rjm_token");
                this.$router.push({ path: '/'})
                }
            }else{
                this.$router.push({ path: '/'})
            }
            this.getAreasForPage()
        },
        methods:{
            crearArea(){
                 
                 axios.post("/api/area/crear", this.area, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                     if(response.status===200){
                         router.push("/area-practica/"+response.data.data._id)
                     }
                 })
             },
            getAreasForPage(){
                axios.get("/api/area/page/"+this.page).then((response) => {
                   
                    this.areas= response.data.areas.docs

                    this.paginacionArea = response.data.areas
                    console.log(this.areas)
                })
            },
           /*Buscar Area por palabra clave */
           buscarArea(event){
            console.log(event.target.value)
                document.getElementById("paginacionAreas").classList.add("hidden")
                 this.areas = []
                if(event.target.value!=''){
                    axios.get("/api/area/search/"+event.target.value).then((response) => {
                        this.areas= response.data.areas
                    })
                }else{
                     this.areas = [];
                     document.getElementById("paginacionAreas").classList.remove("hidden")
                    this.getAreasForPage();
                }
            
           
                
           },
           /*Eliminar proyecto */
           eliminarArea(area){

                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            area.estado = false;

                            console.log(area)

                            axios.put("/api/area/editar/"+area._id, area).then((response)=>{
                                if(response.status===200){
                                    Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.areas=[]
                                            this.getAreasForPage()
                                        } 
                                    });
                                }
                                
                            })
                            
                        }
                    });
            },
           
            
          
            /*Abrir buscador en movil*/
            showSearchMobile(){
                    var btnSearch = document.getElementById("btnSearch");
                    var inputBuscador = document.getElementById("inputBuscador")
                    var encabezadoArea = document.getElementById("encabezadoArea");
                    var contentSearch = document.getElementById("contentSearch");
                    var contentTitulo = document.getElementById("contentTitulo");

                    if(this.valueSearch==false){
                        encabezadoArea.classList.add("grid-cols-6", "gap-x-10");
                        contentSearch.classList.add("col-span-5");
                        contentTitulo.classList.add("hidden");
                        inputBuscador.classList.remove("hidden");
                        btnSearch.classList.add("rounded-r-none", "border-r-0")
                        inputBuscador.classList.add("rounded-l-none", "border-l-0")
                        this.valueSearch=true
                    }else{
                        encabezadoArea.classList.remove("grid-cols-6", "gap-x-10");
                        contentSearch.classList.remove("col-span-5");
                        contentTitulo.classList.remove("hidden");
                        inputBuscador.classList.add("hidden");
                        btnSearch.classList.remove("rounded-r-none", "border-r-0")
                        inputBuscador.classList.remove("rounded-l-none", "border-l-0")
                        this.valueSearch=false
                    }

            },
            prevPage(){
                if(this.page>1){
                    this.page = this.page - 1;
                    window.scrollTo()
                    this.getAreasForPage();
                }
            },
            nextPage(){
                if(this.page < this.paginacionArea.totalPages){
                    this.page = this.page + 1;
                    document.getElementById("contentAreas").scrollTo({top: 0, behavior: 'smooth'})
                    this.getAreasForPage();
                }
            },

        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
</style>