<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
          <!--BODY-->
        <div class="main-section ml-4 mr-4  mt-28 rounded-2xl bg-white w-full md:ml-80 md:mt-28 md:mr-6  py-6 px-6 md:px-10 md:py-10 overflow-auto" style="max-height: 84vh;min-height: 84vh;">
            <!--Encabezado-->
            <section id="encabezadoBlog" class="grid grid-cols-3 md:grid-cols-4 items-center ">
                <!--Search Posts-->
                <div id="contentSearchPosts" class="md:col-span-1">
                    <div class="flex flex-row">
                        <div class="hidden border-2 md:rounded-r-none border-color-primary rounded-lg md:flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>

                        <!--Buscador de los Post del buscador-->
                        <div id="btnSearchPost" v-on:click="showSearchMobilePosts()" class="md:hidden border-2 md:rounded-r-none border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>
                        
                        <input id="inputBuscadorPosts" v-on:keyup="buscarPosts($event)" class="hidden md:block md:border-l-0 md:rounded-l-none text-gray-400 border-2 border-color-primary rounded-lg h-12 w-full px-3" placeholder="Buscar por Nombre"/>
                    </div>
                </div>

                <!--Search Categorias-->
                <div id="contentSearchCategoria" class="hidden md:col-span-1">
                    <div class="flex flex-row">
                        <div class="hidden border-2 md:rounded-r-none border-color-primary rounded-lg md:flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>

                        <!--Buscador de los Post del buscador-->
                        <div id="btnSearchCategoria" v-on:click="showSearchMobileCategorias()" class="md:hidden border-2 md:rounded-r-none border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>
                        
                        <input id="inputBuscadorCategorias" v-on:keyup="buscarCategorias($event)" class="hidden md:block md:border-l-0 md:rounded-l-none text-gray-400 border-2 border-color-primary rounded-lg h-12 w-full px-3" placeholder="Buscar por Nombre"/>
                    </div>
                </div>
                <!--Titulo Blog-->
                <div id="contentTitulo" class="justify-self-center md:col-span-2">
                    <h1 class="poppins-bold text-2xl color-primary text-center">Blog</h1>
                </div>
                 <!--Add Post-->
                <div  id="contentAddPost" class="justify-self-end">
                   <div v-on:click="crearPost()" class="cursor-pointer bg-primary border-2 border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25.554" height="25.554" viewBox="0 0 25.554 25.554">
                        <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M32.928,20.151h-9.6v-9.6a1.591,1.591,0,1,0-3.182,0v9.6h-9.6a1.591,1.591,0,0,0,0,3.182h9.6v9.6a1.591,1.591,0,1,0,3.182,0v-9.6h9.6a1.591,1.591,0,1,0,0-3.182Z" transform="translate(-8.965 -8.965)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
                
                 <!--Add Categoria-->
                <div  id="contentAddCategoria" class="hidden justify-self-end">
                   <div v-on:click="openCloseModalCrearEditarCategoria(true,0)" class="cursor-pointer bg-primary border-2 border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25.554" height="25.554" viewBox="0 0 25.554 25.554">
                        <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M32.928,20.151h-9.6v-9.6a1.591,1.591,0,1,0-3.182,0v9.6h-9.6a1.591,1.591,0,0,0,0,3.182h9.6v9.6a1.591,1.591,0,1,0,3.182,0v-9.6h9.6a1.591,1.591,0,1,0,0-3.182Z" transform="translate(-8.965 -8.965)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
            </section>

            <!--Submenu posts / categorias del blog-->
            <section class="mt-6">
                <div class="flex flex-row justify-center items-center">
                    <button id="btnPosts" v-on:click="changePosts()" class="w-full border border-color-primary py-3 bg-primary rounded-l-lg poppins-light text-base text-white md:w-48">Posts</button>
                    <button id="btnCategorias"  v-on:click="changeCategorias()" class="w-full border border-color-primary py-3 rounded-r-lg poppins-light text-base md:w-48">Categorias</button>
                </div>
            </section>

            <!--Listado de los posts-->
            <section id="vistaPosts" class="mt-6 relative"  style="max-height: 65vh;">
                <div id="contentPosts" class="grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-y-3 overflow-auto">
                    <div v-for="post in posts" :key="post._id" class="grid grid-cols-1 border-2 border-color-primary py-3 pb-4 px-3 rounded-lg">
                        <div class="w-full h-56">
                            <img class="w-full h-full object-cover rounded-lg " :src="post.imagen"/>
                        </div>
                        <div class="col-span-4 row-span-2 flex flex-col justify-between">
                            <div class="text-center py-3 pt-6 ">
                                <h1 class="poppins-semibold  text-lg leading-5 truncate">{{post.titulo}}</h1>
                                <div class="flex flex-wrap gap-2 mt-3 justify-center">
                                    <!--Fecha-->
                                    <p class="text-sm border border-black rounded-2xl px-3 py-1">{{post.fecha}}</p>
                                    <!--Categoria-->
                                    <p class="text-sm bg-black border border-black rounded-2xl px-3 py-1 text-white">{{post.categoria.nombre}}</p>
                                    <!--Borrador - publicado-->
                                    <p class="text-sm bg-yellow-500 rounded-2xl px-3 py-1 text-white" v-if="post.borrador">Borrador</p>
                                    <p class="text-sm bg-green-600 rounded-2xl px-3 py-1 text-white" v-if="!post.borrador">Publicado</p>
                                </div>
                               

                            </div>
                           
                            <!--Acciones-->
                            <div class="flex flex-row gap-x-2  justify-center">
                                <RouterLink :to="'/blog/'+post._id" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>

                                <a  class="bg-blue-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.28" height="15.187" viewBox="0 0 30.28 20.187">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M30.1,13.826A16.861,16.861,0,0,0,15.14,4.5,16.864,16.864,0,0,0,.183,13.826a1.7,1.7,0,0,0,0,1.535A16.861,16.861,0,0,0,15.14,24.687,16.864,16.864,0,0,0,30.1,15.36,1.7,1.7,0,0,0,30.1,13.826ZM15.14,22.163a7.57,7.57,0,1,1,7.57-7.57A7.57,7.57,0,0,1,15.14,22.163Zm0-12.617a5.01,5.01,0,0,0-1.331.2,2.515,2.515,0,0,1-3.517,3.517A5.035,5.035,0,1,0,15.14,9.547Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                 <button v-on:click="eliminarPost(post)" class="bg-red-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

               <!--------------------------------
                -         Paginacion Posts
                ---------------------------------->
                <section id="paginacionPosts" class="mt-6 pb-6 flex flex-row gap-2 justify-center   bottom-12 left-0 md:left-80 right-0">
                    <span v-on:click="prevPagePosts()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="16.227" viewBox="0 0 9.277 16.227">
                        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.047,14.3l6.14-6.135a1.16,1.16,0,1,0-1.642-1.638l-6.956,6.951a1.157,1.157,0,0,0-.034,1.6l6.985,7a1.16,1.16,0,0,0,1.642-1.638Z" transform="translate(-11.251 -6.194)"/>
                        </svg>
                    </span>

                    <ul class="flex flex-row gap-2">
                        <li class=" p-2 flex items-center justify-center rounded-lg w-8 h-8" :class="(pagePost === paginacionPosts.page) ? 'bg-primary text-white': 'border border-color-primary'">{{pagePost}}</li>
                    </ul>

                    <span  v-on:click="nextPagePosts()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.28" height="16.23" viewBox="0 0 9.28 16.23">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M17.729,14.308,11.587,8.171a1.155,1.155,0,0,1,0-1.638,1.17,1.17,0,0,1,1.643,0l6.958,6.953a1.158,1.158,0,0,1,.034,1.6l-6.987,7a1.16,1.16,0,1,1-1.643-1.638Z" transform="translate(-11.246 -6.196)"/>
                        </svg>
                    </span>
                </section>
                
            </section>

             <!--Listado de Categorias del Blog-->
             <section id="vistaCategorias" class="hidden mt-6">
                <div id="contentCategorias" class="grid grid-cols-2 gap-2 md:grid-cols-6 md:gap-x-4 gap-y-3">
                    <div v-for="categoria in categorias" :key="categoria.id" class="grid grid-cols-4 grid-rows-2 h-36 md:h-40 border-2 border-color-primary py-3 px-3 rounded-lg">
                        <div class="col-span-4 row-span-2 flex flex-col justify-center w-full">
                            <div class="text-center py-5">
                                <h1 class="poppins-semibold text-base text-center leading-5">{{categoria.nombre}}</h1>
                            </div>
                           
                            <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <button type="button"  v-on:click="openCloseModalCrearEditarCategoria(true, categoria._id)" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </button>

                                 <button type="button" v-on:click="eliminarCategoria(categoria)" class="bg-red-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--------------------------------
                -       Paginacion Categorias
                ---------------------------------->
                <section id="paginacionCategorias" class="hidden mt-6 pb-6 flex flex-row gap-2 justify-center   bottom-12 left-0 md:left-80 right-0">
                    <span v-on:click="prevPageCategorias()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="16.227" viewBox="0 0 9.277 16.227">
                        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.047,14.3l6.14-6.135a1.16,1.16,0,1,0-1.642-1.638l-6.956,6.951a1.157,1.157,0,0,0-.034,1.6l6.985,7a1.16,1.16,0,0,0,1.642-1.638Z" transform="translate(-11.251 -6.194)"/>
                        </svg>
                    </span>

                    <ul class="flex flex-row gap-2">
                        <li class=" p-2 flex items-center justify-center rounded-lg w-8 h-8" :class="(pageCategoria === paginacionCategorias.page) ? 'bg-primary text-white': 'border border-color-primary'">{{pageCategoria}}</li>
                    </ul>

                    <span  v-on:click="nextPageCategorias()" class="border border-black p-2 flex items-center justify-center rounded-full w-8 h-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.28" height="16.23" viewBox="0 0 9.28 16.23">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M17.729,14.308,11.587,8.171a1.155,1.155,0,0,1,0-1.638,1.17,1.17,0,0,1,1.643,0l6.958,6.953a1.158,1.158,0,0,1,.034,1.6l-6.987,7a1.16,1.16,0,1,1-1.643-1.638Z" transform="translate(-11.246 -6.196)"/>
                        </svg>
                    </span>
                </section>
                
            </section>

            
            <!--Modal crear / editar Categorias-->
            <div class="fixed  inset-0 flex justify-center items-center z-50 w-full" style="background-color: #0000007d" v-if="toggleModalCrearEditarCategoria===true ">
                <form  class="relative bg-white w-full  rounded-xl shadow-2xl  flex flex-col mx-3 " style="height:35vh; max-width:480px;">
                    <!--Header modal--> 
                    <div class="bg-white absolute top-0 right-0 py-3 px-4 flex items-center justify-end rounded-t-xl w-full">
                        <button
                        type="button"
                        v-on:click="openCloseModalCrearEditarCategoria(false, 0)"
                        class=" justify-self-end bg-red-500 hover:bg-red-600 text-2xl w-8 h-8 rounded-full focus:outline-none text-white flex items-center justify-center p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.953" height="20.953" viewBox="0 0 20.953 20.953">
                            <path id="Icon_ionic-md-close" data-name="Icon ionic-md-close" d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z" transform="translate(-7.523 -7.523)" fill="#fff"/>
                        </svg>

                        </button>
                    </div>
                    <!--Body Modal-->
                    <div class="pt-16 py-3 px-4 overflow-x-hidden overflow-y-auto grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-12  md:px-10" >
                      
                        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2" > 
                            <!--Nombre-->
                            <div class="flex flex-col gap-y-1 md:col-span-2">
                                <label class="poppins-medium text-sm">Nombre:</label>
                                <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="categoria.nombre"/>
                            </div>
                        </div>
                    </div>

                    <!--Footer Modal-->
                    <div class="absolute bottom-0 bg-primary rounded-b-xl w-full py-5 px-4 flex justify-end">
                        <button v-if="modoCrear" type="button" v-on:click="crearEditarCategoria()" class="bg-white rounded-xl poppins-regular text-base w-36 h-12">Agregar</button>
                        <button v-if="modoEditar" type="button" v-on:click="crearEditarCategoria()" class="bg-white rounded-xl poppins-regular text-base w-36 h-12">Editar</button>

                    </div>

                </form>
            </div>
           
        </div> 
    </div>
</template>

<script>
    import axios from 'axios'
    import router from "../../router"  
    import Menu from '../../components/Menu';
    import Swal from 'sweetalert2'
    import moment from 'moment';

    export default {
        name: "Blog",
        components: {Menu},
        data(){
            return {
                usuario:{
                    username:'',
                    password:''
                },
                posts:[],
                paginacionPosts:[],
                categorias: [],
                categoria:{nombre:'', estado:true},
                paginacionCategorias:[],
                toggleModalCrearEditarCategoria: false,
                valueSearch: false, /*Se usa para mostrar y desaparecer el buscador en mobile */
                valueSearchCategorias: false,
                post:{
                    titulo:'Post Borrador',
                    categoria:"63641683f6009b2ae546f74f",//defecto sin categoria
                    descripcion:"",
                    imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    fecha:"",
                    slug:"",
                    estado:true,
                    borrador:true,
                    modoEditar:false,
                    modoCrear:false,
                },
                pagePost:1,
                pageCategoria:1,
                APIURL: "/api"
            }
        },
        mounted(){
            //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
            if(sessionStorage.getItem("rjm_token")===null){
                router.push("/")
            }
            this.getPostsBlogForPage()
        },
        methods:{
            crearPost(){
                 
                axios.post(this.APIURL+"/blog/post/create", this.post).then((response)=>{
                    if(response.status===200){
                        router.push("/blog/"+response.data.data._id)
                    }
                })
            },
            getPostsBlogForPage(){
                axios.get(this.APIURL+"/blog/post/page/"+this.pagePost+"/cat/all/orden/-1").then((response) => {
                    this.posts=[]

                    response.data.blogPosts.docs.forEach(post => {
                        if(post.categoria!=undefined){
                            axios.get(this.APIURL+"/blog/categoria/"+post.categoria).then((categoria) => {
                                console.log(categoria)
                                this.posts.push({
                                    _id: post._id,
                                    titulo: post.titulo,
                                    categoria: categoria.data,
                                    imagen:post.imagen,
                                    descripcion: post.descripcion,
                                    slug:"",
                                    fecha:moment(post.fecha).format("DD/MM/yyyy"),
                                    estado: post.estado,
                                    borrador: post.borrador
                                })
                            })
                        }
                    });

                    this.paginacionPosts = response.data.blogPosts;
                    console.log(this.paginacionPosts)
                })
            },
            getCategoriasForPage(){
                axios.get(this.APIURL+"/blog/categoria/page/"+this.pageCategoria).then((response) => {
                    this.categorias = response.data.blogCategoria.docs;
                    this.paginacionCategorias = response.data.blogCategoria
                })
            },
           /*Buscar Proyecto por palabra clave */
           buscarPosts(event){
            console.log(event.target.value)
                document.getElementById("paginacionPosts").classList.add("hidden")
                 this.posts = []
                if(event.target.value!=''){
                    
                    axios.get(this.APIURL+"/blog/post/search/"+event.target.value).then((response) => {
                        response.data.posts.forEach(post => {
                            if(post.categoria!=undefined){
                                axios.get(this.APIURL+"/blog/categoria/"+post.categoria).then((categoria) => {
                                    console.log(categoria)
                                    this.posts.push({
                                        _id: post._id,
                                        titulo: post.titulo,
                                        categoria: categoria.data,
                                        imagen:post.imagen,
                                        descripcion: post.descripcion,
                                        slug:"",
                                        fecha:moment(post.fecha).format("DD/MM/yyyy"),
                                        estado: post.estado,
                                        borrador: post.borrador
                                    })
                                })
                            }
                        });
                    })
                }else{
                     this.posts = []
                    this.getPostsBlogForPage();
                document.getElementById("paginacionPosts").classList.remove("hidden")

                }
            
           
                
           },
           /*Buscar cartegorias por palabra clave */
           buscarCategorias(event){
            console.log(event.target.value)
          
                 this.categorias = []
                if(event.target.value!=''){
                    
                    document.getElementById("paginacionCategorias").classList.add("hidden")

                    axios.get(this.APIURL+"/blog/categoria/search/"+event.target.value).then((response) => {
                        this.categorias = response.data.categoriasBlog
                    })
                }else{
                     this.categorias = []
                    this.getCategoriasForPage();
                    document.getElementById("paginacionCategorias").classList.remove("hidden")

                }
            
           
                
           },
           /*Eliminar post */
           eliminarPost(post){

                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            post.categoria = post.categoria._id
                            post.estado = false;
                            post.slug = "";
                            console.log(post)

                            axios.put(this.APIURL+"/blog/post/"+post._id, post).then((response)=>{
                                if(response.status===200){
                                      Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.posts=[]
                                            this.getPostsBlogForPage()
                                        } 
                                    });
                                }
                                
                            })
                            
                        }
                    });
            },
            crearEditarCategoria(){
                /**Crear categoria */
                if(this.categoria._id===undefined){
                    console.log(this.categoria)
                    axios.post(this.APIURL+"/blog/categoria/create", this.categoria,  { headers: {'x-access-token': sessionStorage.getItem("ketsin_token")} }).then((response)=>{
                        if(response.status===200){
                            this.toggleModalCrearEditarCategoria = false,
                            this.categoria={nombre:"", estado:true}
                             Swal.fire({
                                title: response.data.message,
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.getCategoriasForPage()
                                    } 
                                });
                        }
                    })
                }else{/*Editar Proyecto */
                     axios.put(this.APIURL+"/blog/categoria/"+this.categoria._id, this.categoria,  { headers: {'x-access-token': sessionStorage.getItem("ketsin_token")} }).then((response)=>{
                        if(response.status===200){
                            this.toggleModalCrearEditarCategoria = false
                           this.categoria={nombre:"", estado:true}
                            Swal.fire({
                            title: response.data.message,
                                icon: 'success',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.getCategoriasForPage()
                                } 
                            });
                        }
                    })
                }
               
            },
            eliminarCategoria(categoria){

                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            categoria.estado = false;

                           axios.put(this.APIURL+"/blog/categoria/"+categoria._id, categoria,  { headers: {'x-access-token': sessionStorage.getItem("ketsin_token")} }).then((response)=>{
                                if(response.status===200){
                                    this.toggleModalCrearEditarTipo = false
                                    this.categoria={nombre:"", estado:true}
                                    Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.categorias=[]
                                            this.getCategoriasForPage()
                                        } 
                                    });
                                }
                            })
                            
                        }
                    });
            },
            
           /*Cambiar a vista Categorias del Blog*/
           changeCategorias(){
                var vistaPosts=document.getElementById("vistaPosts");
                var vistaCategorias=document.getElementById("vistaCategorias");
                var btnCategorias=document.getElementById("btnCategorias");
                var btnPosts=document.getElementById("btnPosts");

                vistaCategorias.classList.remove("hidden")
                vistaPosts.classList.add("hidden")
                btnPosts.classList.remove("bg-primary")
                btnPosts.classList.add("color-primary")
                btnCategorias.classList.add("bg-primary")
                btnCategorias.classList.add("text-white")

                /*Aparecer botones de crear depende de la ventana seleccionaa */
                document.getElementById("contentAddCategoria").classList.remove("hidden")
                document.getElementById("contentAddPost").classList.add("hidden")
                
                /*Aparecer paginacion de la ventana  seleccionada*/
                document.getElementById("paginacionPosts").classList.add("hidden")
                document.getElementById("paginacionCategorias").classList.remove("hidden")

                /*Aparecer Buscador de cada ventana seleccionada */
                document.getElementById("contentSearchPosts").classList.add("hidden")
                document.getElementById("contentSearchCategoria").classList.remove("hidden")

               
                /*Titulo del blog aparece */
                document.getElementById("contentTitulo").classList.remove("hidden");
                document.getElementById("inputBuscadorPosts").classList.add("hidden");
                document.getElementById("encabezadoBlog").classList.remove("grid-cols-6", "gap-x-10");
                document.getElementById("btnSearchCategoria").classList.remove("rounded-r-none", "border-r-0")
                document.getElementById("contentSearchCategoria").classList.remove("col-span-5")


                this.getCategoriasForPage()


                
           },
           /*cambiar a vista proyectos*/
            changePosts(){
                var vistaPosts=document.getElementById("vistaPosts");
                var vistaCategorias=document.getElementById("vistaCategorias");
                var btnCategorias=document.getElementById("btnCategorias");
                var btnPosts=document.getElementById("btnPosts");

                vistaPosts.classList.remove("hidden");
                vistaCategorias.classList.add("hidden");
                btnCategorias.classList.remove("bg-primary")
                btnCategorias.classList.remove("text-white")
                btnPosts.classList.remove("color-primary")
                btnPosts.classList.add("bg-primary")

               /*Aparecer botones de crear depende de la ventana seleccionaa */
               document.getElementById("contentAddCategoria").classList.add("hidden")
                document.getElementById("contentAddPost").classList.remove("hidden")
                
                /*Aparecer paginacion de la ventana  seleccionada*/
                document.getElementById("paginacionPosts").classList.remove("hidden")
                document.getElementById("paginacionCategorias").classList.add("hidden")
                
                 /*Aparecer Buscador de cada ventana seleccionada */
                 document.getElementById("contentSearchPosts").classList.remove("hidden")
                document.getElementById("contentSearchCategoria").classList.add("hidden")
                
                 /*Titulo del blog aparece */
                 document.getElementById("contentTitulo").classList.remove("hidden");
                document.getElementById("inputBuscadorCategorias").classList.add("hidden");
                document.getElementById("encabezadoBlog").classList.remove("grid-cols-6", "gap-x-10");
                document.getElementById("btnSearchPost").classList.remove("rounded-r-none", "border-r-0")
                document.getElementById("contentSearchPosts").classList.remove("col-span-5")
            },
            /*Show Search in mobile*/
            showSearchMobilePosts(){
                 var btnSearch = document.getElementById("btnSearchPost");
                var inputBuscador = document.getElementById("inputBuscadorPosts")
                var encabezadoBlog = document.getElementById("encabezadoBlog");
                var contentSearch = document.getElementById("contentSearchPosts");
                var contentTitulo = document.getElementById("contentTitulo");

                if(this.valueSearch==false){
                    encabezadoBlog.classList.add("grid-cols-6", "gap-x-10");
                    contentSearch.classList.add("col-span-5");
                    contentTitulo.classList.add("hidden");
                    inputBuscador.classList.remove("hidden");
                    btnSearch.classList.add("rounded-r-none", "border-r-0")
                    inputBuscador.classList.add("rounded-l-none", "border-l-0")
                    this.valueSearch=true
                }else{
                    encabezadoBlog.classList.remove("grid-cols-6", "gap-x-10");
                    contentSearch.classList.remove("col-span-5");
                    contentTitulo.classList.remove("hidden");
                    inputBuscador.classList.add("hidden");
                    btnSearch.classList.remove("rounded-r-none", "border-r-0")
                    inputBuscador.classList.remove("rounded-l-none", "border-l-0")
                    this.valueSearch=false
                }

            },
            showSearchMobileCategorias(){
                 var btnSearch = document.getElementById("btnSearchCategoria");
                var inputBuscador = document.getElementById("inputBuscadorCategorias")
                var encabezadoBlog = document.getElementById("encabezadoBlog");
                var contentSearch = document.getElementById("contentSearchCategoria");
                var contentTitulo = document.getElementById("contentTitulo");
                
                if(this.valueSearchCategorias==false){

                    encabezadoBlog.classList.add("grid-cols-6", "gap-x-10");
                    contentSearch.classList.add("col-span-5");
                    contentTitulo.classList.add("hidden");
                    inputBuscador.classList.remove("hidden");
                    btnSearch.classList.add("rounded-r-none", "border-r-0")
                    inputBuscador.classList.add("rounded-l-none", "border-l-0")
                    this.valueSearchCategorias=true
                }else{
                    encabezadoBlog.classList.remove("grid-cols-6", "gap-x-10");
                    contentSearch.classList.remove("col-span-5");
                    contentTitulo.classList.remove("hidden");
                    inputBuscador.classList.add("hidden");
                    btnSearch.classList.remove("rounded-r-none", "border-r-0")
                    inputBuscador.classList.remove("rounded-l-none", "border-l-0")
                    this.valueSearchCategorias=false
                }

            },
            prevPagePost(){
                if(this.pagePost>1){
                    this.pagePost = this.pagePost - 1;
                    this.getPostsBlogForPage();
                }
            },
            nextPagePost(){
                if(this.pagePost < this.paginacionPosts.totalPages){
                    this.pagePost = this.pagePost + 1;
                    this.getPostsBlogForPage();
                }
            },
            prevPageCategoria(){
                if(this.pageCategoria>1){
                    this.pageCategoria = this.pageCategoria - 1;
                    this.getCategoriasForPage();
                }
            },
            nextPageCategoria(){
                if(this.pageCategoria < this.paginacionPosts.totalPages){
                    this.pageCategoria = this.pageCategoria + 1;
                    this.getCategoriasForPage();
                }
            },
             openCloseModalCrearEditarCategoria(value, id){
                console.log(id)
                this.toggleModalCrearEditarCategoria = value
                this.categoria={nombre:"", estado:true};
                if(id!=0){
                    this.modoEditar = true;
                    this.modoCrear = false
                    axios.get(this.APIURL+"/blog/categoria/"+id).then((response) => {
                        console.log(response)
                        this.categoria = response.data;
                        this.getCategoriasForPage();

                    })
                }else{
                     this.modoEditar = false;
                    this.modoCrear = true;
                }
            },

        }
    }
</script>
