import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Mensaje from '../views/Mensaje/Mensaje.vue'
import Area from '../views/Area/Areas.vue'
import EditarArea from '../views/Area/EditarArea.vue'
import Paginas from '../views/Design/Paginas.vue'
import Home from '../views/Design/Home.vue'
import AreasDesign from '../views/Design/AreasDesign.vue'
import Nosotros from '../views/Design/Nosotros.vue'
import Relocated from '../views/Design/Relocated.vue'
import Partner from '../views/Design/Partner.vue'
import Empresas from '../views/Design/Empresas.vue'
import Blog from '../views/Blog/Blog.vue'
import EditarPost from '../views/Blog/EditarPost.vue'
import Contact from '../views/Design/Contact.vue'
import Footer from '../views/Design/Footer.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/mensajes',
    name: 'Mensajes',
    component: Mensaje
  },
  {
    path: '/area-practica/',
    name: 'Area',
    children:[
      {
        path: '',
        component: Area
      },
      {
        path: ':id',
        name: "EditarArea",
        component: EditarArea
      }
    ]
  },
  {
    path: '/blog/',
    name: 'Blog',
    children:[
      {
        path: '',
        component: Blog
      },
      {
        path: ':id',
        component: EditarPost
      }
    ],
  },
  {
    path: '/design/',
    name: 'Design',
    children:[
      {
        path: 'paginas',
        component: Paginas
      },
      {
        path: 'home',
        component: Home
      },
      {
        path: 'relocation',
        component: Relocated
      },
      {
        path: 'empresas',
        component: Empresas
      },
      {
        path: 'partner',
        component: Partner
      },
      {
        path: 'areas-practica',
        component: AreasDesign
      },
      {
        path: 'nosotros',
        component: Nosotros
      }, 
      {
        path: 'contact',
        component: Contact
      },
      {
        path: 'Footer',
        component: Footer
      }
    ]
  }
  
]

const router = new createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history'
})

export default router
