<!-- eslint-disable max-len -->
<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:grid md:grid-cols-12" >
        <!--Parte 1-->
        <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 1</h2>
           
            <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Nombre:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.titulo" name="Valor"/>
                </div>

            <!--telefono-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Teléfono:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.telefono" name="Valor"/>
                </div>

                <!--correo-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Correo de Contacto:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.correo" name="Valor"/>
                </div>

                <!--waze-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Waze:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.waze" name="Valor"/>
                </div>

                 <!--maps-->
                 <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Maps:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.maps" name="Valor"/>
                </div>
            </div>
        </div>


        <!--PARTE 2 - SECCION FORMULARIO-->
        <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 2 - Formulario</h2>
            <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
                <!--Input 1-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Entrada 1:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.inputOne" name="Valor"/>
                </div>

                <!--INput 2-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Entrada 2:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.inputTwo" name="Valor"/>
                </div>

                <!--INput 3-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Entrada 3:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.inputThree" name="Valor"/>
                </div>

                <!--Message-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Mensaje:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.inputMessage" name="Valor"/>
                </div>

                <!--Boton-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Botón:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.btn" name="Valor"/>
                </div>

                <!--Informacion de contacto-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Correo para el contacto:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-model="contactoTemp.form.correoContacto" name="Valor"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['contacto'],
  data() {
    return {
      contactoTemp: {
        titulo: "",
        telefono: "",
        correo:"",
        waze:"",
        maps: "",
        form: {
            inputOne: '',
            inputTwo: '',
            inputThree: '',
            inputMessage: '',
            btn: '',
            correoContacto: ''
        },
      },
    };
  },
  watch: {
    contacto() {
      this.contactoTemp = this.contacto;
    },
  },
};
</script>

<style>

</style>
