<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
        <!--Body-->
        <section class="main-section w-full md:grid md:grid-cols-12 ml-4 mr-4 md:ml-80  md:mr-6 ">
            <!--Submenu Diseño-->
            <div class="hidden md:flex md:flex-col bg-primary md:col-span-2 md:mt-28 rounded-l-2xl py-12" style="min-height: 82vh">
                <div class="w-full px-3">
                    <h2 class="poppins-regular text-white text-xl text-center">Secciones</h2>
                </div>
                <ul class="mt-12 w-full">
                    <li v-on:click="fondoSubmenuItem('Portada')" class="w-full flex">
                        <button id="btnPortada" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white text-white w-full text-base text-left">1. Portada</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionOne')" class="w-full flex">
                        <button id="btnSeccionOne" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">2. Sección 1</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionTwo')" class="w-full flex">
                        <button id="btnSeccionTwo" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">3. Sección 2</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionThree')" class="w-full flex">
                        <button id="btnSeccionThree" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">4. Sección 3</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionFour')" class="w-full flex">
                        <button id="btnSeccionFour" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">5. Sección 4</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionFive')" class="w-full flex">
                        <button id="btnSeccionFive" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">6. Sección 5</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('SeccionSix')" class="w-full flex">
                        <button id="btnSeccionSix" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">6. Sección 6</button>
                    </li>
                </ul>

            </div>
            <form @submit.prevent="guardarNosotros()" class="mt-28 rounded-xl md:ml-0 md:rounded-2xl bg-white w-full  py-4 px-4 md:px-10 md:py-10 overflow-auto md:col-span-10 md:rounded-l-none" style="max-height: 84vh;">
                <!--Encabezado-->
                <section id="encabezadoMenu" class="grid grid-cols-2 md:grid-cols-12 items-center md:mb-12 gap-x-3">
                    <!--Titulo nosotros-->
                    <div id="contentTitulo" class="justify-self-center col-span-2 md:col-span-8 md:col-start-3" >
                        <h1 class="poppins-bold text-2xl color-primary text-center">Diseño Pages</h1>
                        <p class="poppins-regular text-center">Páginas > <span class="poppins-semibold">About</span></p>
                    </div>

                    <!--Select submenu-->
                    <div class="col-span-1 md:hidden mt-4">
                        <select class="bg-primary rounded-xl py-2 text-white poppins-regular px-3 w-full" v-on:change="fondoSubmenuItemMobile($event)" style="max-width:150px">
                            <option value="Portada">Portada</option>
                            <option value="SeccionOne">Seccion 1</option>
                            <option value="SeccionTwo">Seccion 2</option>
                            <option value="SeccionThree">Seccion 3</option>
                            <option value="SeccionFour">Seccion 4</option>
                            <option value="SeccionFive">Seccion 5</option>
                            <option value="SeccionSix">Seccion 6</option>

                        </select>
                    </div>

                    <!--Acciones-->
                    <div class="flex flex-row gap-x-1   justify-end col-span-1 md:col-span-2 mt-4">
                        <button type="submit" class="bg-green-600 w-9 h-9 md:w-12 md:h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button>
                        <a  class="bg-blue-500 w-9 h-9 md:w-12 md:h-12 px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                            <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                            </svg>
                        </a>
                    </div>

                </section>

                <!--Body-->
                <section class="pt-6 md:pt-0 overflow-x-hidden overflow-y-auto grid grid-cols-1">
                 

                    <!--Portada-->
                    <div id="Portada_nosotros" class="hidden">
                        <PortadaNosotros :nosotros="nosotros" @nosotros="getChangesNosotros" @imgFileFondoPortadaNosotros="getFileFondoPortadaNosotros"  />
                    </div>
                    <!--Section One-->
                    <div id="SeccionOne_nosotros" class="hidden">
                        <SectionOneNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" @imgFileSeccionOneAbout="getFileFondoSeccionOneAbout" ></SectionOneNosotros>
                    </div>

                     <!--Section Two-->
                     <div id="SeccionTwo_nosotros" class="hidden">
                        <SectionTwoNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" @imgFileSeccionTwoAbout="getFileFondoSeccionTwoAbout" ></SectionTwoNosotros>
                    </div>

                    <!--Section Three-->
                    <div id="SeccionThree_nosotros" class="hidden">
                        <SectionThreeNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" @imgFileSeccionThreeAbout="getFileFondoSeccionThreeAbout" ></SectionThreeNosotros>
                    </div>

                    <!--Section Four-->
                    <div id="SeccionFour_nosotros" class="hidden">
                        <SectionFourNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" ></SectionFourNosotros>
                    </div>

                     <!--Section Five-->
                    <div id="SeccionFive_nosotros" class="hidden">
                        <SectionFiveNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" ></SectionFiveNosotros>
                    </div>

                     <!--Section Six-->
                     <div id="SeccionSix_nosotros" class="hidden">
                        <SectionSixNosotros  :nosotros="nosotros" @nosotros="getChangesNosotros" ></SectionSixNosotros>
                    </div>
                   
                   
                </section>
            </form>


        </section>
       
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

/*Componentes */
import Menu from '../../components/Menu';
import PortadaNosotros from '../../components/nosotros/PortadaNosotros.vue';
import SectionOneNosotros from '../../components/nosotros/SectionOneNosotros.vue';
import SectionTwoNosotros from '../../components/nosotros/SectionTwoNosotros.vue';
import SectionThreeNosotros from '../../components/nosotros/SectionThreeNosotros.vue';
import SectionFourNosotros from '../../components/nosotros/SectionFourNosotros.vue';
import SectionFiveNosotros from '../../components/nosotros/SectionFiveNosotros.vue';
import SectionSixNosotros from '../../components/nosotros/SectionSixNosotros.vue';






/*Services */
import FileServices from '../../services/fileService.js';

export default {
    name:"nosotros",
    components: { Menu, PortadaNosotros, SectionOneNosotros, SectionTwoNosotros, SectionThreeNosotros, SectionFourNosotros, SectionFiveNosotros, SectionSixNosotros },
    data(){
        return {
            nosotros:{
                portada:{
                    imagen:"",
                    descripcion:"",
                },
                secciones:{
                    seccionOne:{imagen:"", titulo:"", subtitulo:""},
                    seccionTwo:{imagen:"", titulo:"", subtitulo:""},
                    seccionThree:{imagen:"", titulo:"", subtitulo:""},
                    seccionFour:{titulo:""},
                    seccionFive:{titulo:""},
                    seccionSix:{titulo:""}
                }
            },
            fileFondoPortadaNosotros:"",
            filePortadaOne:"",
            fileSeccionOne:"",
            fileSeccionTwo:"",
            fileSeccionThree:"",


            itemSubmenuAnterior:"",
            itemSubmenuActual:"",

            APIURL: "/api"

        }
    },
     mounted(){
        this.fondoSubmenuItem("Portada")
        this.getNosotros();

     },
     methods:{
        getNosotros(){
            axios.get(this.APIURL+"/nosotros").then((resp) => {
                console.log(resp)
                this.nosotros=resp.data.SobreNosotros[0]
            })
        },
        guardarNosotros(){
           
            if(this.nosotros!=null){
                var formData;
                var nosotrosEditado  = this.nosotros;

                console.log(nosotrosEditado)
                
                /*Subir imagen fondo de sobre nosotros */
                formData = new FormData();
                console.log(this.fileFondoPortadaNosotros)
                formData.append('upload',this.fileFondoPortadaNosotros )
                FileServices.subirArchivos(formData).then(res=>{
                    console.log(res.location)
                    if(res.location!=undefined){
                        nosotrosEditado.portada.imagen=res.location;
                        console.log(nosotrosEditado)
                
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }
                })

                 /*Subir imagen Seccion One */
                 formData = new FormData();
                console.log(this.fileSeccionOne)
                formData.append('upload',this.fileSeccionOne )
                FileServices.subirArchivos(formData).then(res=>{
                    console.log(res.location)
                    if(res.location!=undefined){
                        nosotrosEditado.secciones.seccionOne.imagen=res.location;
                        console.log(nosotrosEditado)
                
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }
                })

                /*Subir imagen Seccion Two */
                formData = new FormData();
                console.log(this.fileSeccionTwo)
                formData.append('upload',this.fileSeccionTwo )
                FileServices.subirArchivos(formData).then(res=>{
                    console.log(res.location)
                    if(res.location!=undefined){
                        nosotrosEditado.secciones.seccionTwo.imagen=res.location;
                        console.log(nosotrosEditado)
                
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }else{
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }
                })

                 /*Subir imagen Seccion Two */
                formData = new FormData();
                console.log(this.fileSeccionThree)
                formData.append('upload',this.fileSeccionThree )
                FileServices.subirArchivos(formData).then(res=>{
                    console.log(res.location)
                    if(res.location!=undefined){
                        nosotrosEditado.secciones.seccionThree.imagen=res.location;
                        console.log(nosotrosEditado)
                
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }else{
                        //actualizar la home
                        axios.put(this.APIURL+"/nosotros", nosotrosEditado).then((response)=>{
                            console.log(response)
                            
                        })
                    }
                })



                 Swal.fire({
                        title: "Nosotros Guardado con éxito ",
                        icon: 'success',
                    })
            }else{
                 Swal.fire({
                    title: "Nosotros Guadado con éxito",
                    icon: 'success',
                })
            }
            

        },
       
        
        getFileFondoPortadaNosotros(data){
             this.fileFondoPortadaNosotros = data;
        },

        getFileFondoSeccionOneAbout(data){
             this.fileSeccionOne = data;
        },

        getFileFondoSeccionTwoAbout(data){
             this.fileSeccionTwo = data;
        },

        getFileFondoSeccionThreeAbout(data){
             this.fileSeccionThree = data;
        },

        fondoSubmenuItem(itemMenu){

            console.log(this.itemSubmenuAnterior)
            document.getElementById("btn"+itemMenu).classList.add("bg-white")
            document.getElementById("btn"+itemMenu).classList.remove("text-white")

            document.getElementById(itemMenu+"_nosotros").classList.remove("hidden")

            if(this.itemSubmenuAnterior!="" && itemMenu!=this.itemSubmenuAnterior){
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.remove("bg-white")
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.add("text-white")
            document.getElementById(this.itemSubmenuAnterior+"_nosotros").classList.add("hidden")

            }
            this.itemSubmenuAnterior = itemMenu;

        },
         fondoSubmenuItemMobile(event){
            var itemMenu = event.target.value
            console.log(this.itemSubmenuAnterior)
            document.getElementById("btn"+itemMenu).classList.add("bg-white")
            document.getElementById("btn"+itemMenu).classList.remove("text-white")

            document.getElementById(itemMenu+"_nosotros").classList.remove("hidden")

            if(this.itemSubmenuAnterior!="" && itemMenu!=this.itemSubmenuAnterior){
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.remove("bg-white")
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.add("text-white")
            document.getElementById(this.itemSubmenuAnterior+"_nosotros").classList.add("hidden")

            }
            this.itemSubmenuAnterior = itemMenu;
            

        },
        getChangesNosotros(data){
            this.nosotros = data
        }
     }
}   

</script>