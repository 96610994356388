<template>
    <div class="w-full h-screen bg-primary flex items-center justify-center px-6">
        <form @submit.prevent="acceder()" class="bg-white px-6 py-10  md:px-12 md:py-12 rounded-xl w-full md:w-auto">
            <div class="flex flex-col items-center justify-center">
                <img class="w-40  mb-2" src="../assets/img/Logo-RJM_Flat-02.svg">
                <h1 class="poppins-bold text-xl mt-3 color-primary">INICIAR SESIÓN</h1>
                <p class="mb-3 poppins-light text-gray-600">Iniciar Sesión con tu cuenta</p>
            </div>
            <!--Campos del Formulario-->
            <div class="flex flex-col gap-y-3 ">
                <!--Usuario-->
                <div class="flex flex-row">
                    <div class="bg-primary rounded-l-lg px-4 py-2 flex flex-col justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.333" viewBox="0 0 21 23.333">
                            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-3.5 -2.333)">
                                <path id="Trazado_5" data-name="Trazado 5" d="M23.333,24.5V22.167A4.667,4.667,0,0,0,18.667,17.5H9.333a4.667,4.667,0,0,0-4.667,4.667V24.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.333"/>
                                <path id="Trazado_6" data-name="Trazado 6" d="M18.667,8.167A4.667,4.667,0,1,1,14,3.5,4.667,4.667,0,0,1,18.667,8.167Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.333"/>
                            </g>
                        </svg>
                    </div>
                    <div class="w-full">
                        <input class="w-full border border-gray-400 rounded-r-lg px-4 py-3 poppins-light" v-model="usuario.username" placeholder="Usuario">
                    </div>
                </div>
                <!--Contraseña-->
                <div class="flex flex-row">
                    <div class="bg-primary rounded-l-lg px-4 py-2 flex flex-col justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="20.667" viewBox="0 0 23.333 25.667">
                        <g id="Icon_feather-lock" data-name="Icon feather-lock" transform="translate(-2.333 -1.167)">
                            <path id="Trazado_3" data-name="Trazado 3" d="M5.833,12.833H22.167A2.333,2.333,0,0,1,24.5,15.167v8.167a2.333,2.333,0,0,1-2.333,2.333H5.833A2.333,2.333,0,0,1,3.5,23.333V15.167A2.333,2.333,0,0,1,5.833,12.833Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.333"/>
                            <path id="Trazado_4" data-name="Trazado 4" d="M8.167,12.833V8.167a5.833,5.833,0,1,1,11.667,0v4.667" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.333"/>
                        </g>
                        </svg>
                    </div>
                    <div class="w-full">
                        <input type="password" class="w-full border border-gray-400 rounded-r-lg px-4 py-3 poppins-light" v-model="usuario.password" placeholder="Contraseña">
                    </div>
                </div>
            </div>
            <!--Boton de Ingreso-->
            <div class="mt-6 flex justify-center">
                <button id="submit-login" type="submit" class="poppins-regular text-lg text-white bg-primary rounded-xl flex flex-row items-center gap-x-3 px-6 py-3">
                   Ingresar
                   <svg for="submit-login" xmlns="http://www.w3.org/2000/svg" width="30" height="25.5" viewBox="0 0 36 31.5">
                    <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M13.5,0V4.5h18V27h-18v4.5H36V0ZM18,9v4.5H0V18H18v4.5l9-6.75Z" fill="#fff"/>
                    </svg>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
 import router from "../router" 
 import Swal from 'sweetalert2' 
    import axios from 'axios'
    export default {
        name: "Login",
        data(){
            return {
                usuario:{
                    username:'',
                    password:''
                }
            }
        },
        methods:{
            acceder(){
                axios.post("/api/auth/signin", this.usuario ).then((response) => {
                    console.log(response)
                    sessionStorage.setItem('rjm_token', response.data.accessToken);
                    router.push("/mensajes")
                    
                }).catch(error=>{
                    console.log(error)
                    if(error.response.status===401 ||error.response.status===404){
                        Swal.fire({
                            title: 'Usuario o Contraseña Inválidos',
                            icon: 'error',
                        
                        })
                    }
                    
                })
            }
        }
    }
</script>