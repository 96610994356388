import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/main.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

import CKEditor from '@ckeditor/ckeditor5-vue';

axios.defaults.baseURL = `https://rjm-backends-prod-5obva.ondigitalocean.app/v1`
//axios.defaults.baseURL = `http://localhost:3700`

createApp(App)
    .use(router, VueAxios, axios,CKEditor)
    .mount('#app')
