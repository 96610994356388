<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col gap-y-3 md:gap-y-6 md:grid md:grid-cols-12" >
    <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
      <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 1 - Como podemos ayudarte</h2>
      <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
        <!--Nombre-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
            <label class="poppins-medium text-sm">Titulo:</label>
            <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.comoPodemosAyudarte.titulo" name="Valor"/>
        </div>

        <!--Boton-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
          <label class="poppins-medium text-sm">Botón:</label>
          <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.comoPodemosAyudarte.btn" name="Valor"/>
        </div>
      </div>
    </div>

    <!--Parte 2-->
    <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
        <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 2 - Logo</h2>
        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
         <!--Descripcion-->
          <div class="flex flex-col gap-y-1 md:col-span-2">
              <label class="poppins-medium text-sm">Descripción:</label>
              <ckeditor :editor="editorDescripcion" v-model="footerTemp.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
          </div>
        
        </div>
    </div>


    <!--Parte 3 Ofinas-->
    <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
      <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 3 - Oficinas</h2>
      <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
        <!--text-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
          <label class="poppins-medium text-sm">Titulo:</label>
          <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.oficinas.titulo" name="Valor"/>
        </div>

        <!--Direccion-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
          <label class="poppins-medium text-sm">Descripción:</label>
          <ckeditor :editor="editorDescripcion" v-model="footerTemp.oficinas.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
        </div>
      </div>
  </div>

  <!--Parte 4 Contacto-->
  <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
      <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 4 - Contacto</h2>
      <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
        <!--text-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
          <label class="poppins-medium text-sm">Titulo:</label>
          <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.oficinas.titulo" name="Valor"/>
        </div>

        <!--Direccion-->
        <div class="flex flex-col gap-y-1 md:col-span-2">
          <label class="poppins-medium text-sm">Descripción:</label>
          <ckeditor :editor="editorDescripcion" v-model="footerTemp.contactInformation.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
        </div>
      </div>
  </div>


    <!--Derechos-->
    <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
        <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 5 - Derechos Reservados</h2>
        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
          <!--Titulo-->
          <div class="flex flex-col gap-y-1 md:col-span-2">
              <label class="poppins-medium text-sm">Texto:</label>
              <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.derechos" name="Valor"/>
          </div>
        </div>
        
      
    </div>

  
     <!--redes-->
     <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4 md:col-span-8 md:col-start-3 md:grid-cols-12 md:py-8  md:px-8" >
        <h2 class="poppins-medium text-lg md:text-2xl col-span-2 md:col-span-12">Parte 6 - Redes Sociales</h2>
        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
          <!--Instagram-->
          <div class="flex flex-col gap-y-1 md:col-span-2">
              <label class="poppins-medium text-sm">Instagram:</label>
              <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.redesSociales.instagram" name="Valor"/>
          </div>
        </div>

        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
          <!--Linkedin-->
          <div class="flex flex-col gap-y-1 md:col-span-2">
              <label class="poppins-medium text-sm">LinkedIn:</label>
              <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.redesSociales.linkedin" name="Valor"/>
          </div>
        </div>

        <div class="flex flex-col gap-3 col-span-2 md:col-span-12 md:grid md:grid-cols-2 md:items-center" >
          <!--Linkedin-->
          <div class="flex flex-col gap-y-1 md:col-span-2">
              <label class="poppins-medium text-sm">Facebook:</label>
              <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" v-on:change="detectInput()"  v-model="footerTemp.redesSociales.facebook" name="Valor"/>
          </div>
        </div>
        
      
    </div>

  
</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  props: ['footer'],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      footerTemp: {
        comoPodemosAyudarte: {
            titulo: '',
            btn: '',
        },
        logo: '',
        descripcion: '',
        oficinas:{
            titulo: '',
            descripcion: ''
        },
        contactInformation: {
            titulo: '',
            descripcion:''
        },
        logoTwo:'',
        derechos: '',
        redesSociales:{
            instagram: '',
            linkedin: '',
            facebook: ''
        }
      },
      editorDescripcion: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [],
        },
      },
    };
  },
  methods: {
    detectInput() {
      if (this.footerTemp.descripcion !== '') {
        this.$emit('footer', this.footerTemp);
      }
    },
  },
  watch: {
    footer() {
      this.footerTemp = this.footer;
    },
  },
};
</script>

<style>

</style>
