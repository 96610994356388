<template>
    <div class="flex flex-row">
         <!--MENU-->
        <Menu/>

        <div class="main-section ml-4 mr-4  mt-28 rounded-2xl bg-white w-full md:ml-80 md:mt-28 md:mr-6  py-6 px-6 md:px-10 md:py-10 overflow-auto" style="min-height: 84vh; max-height: 90vh">
             <!--Encabezado-->
           <section id="encabezadoMenu" class="grid grid-cols-3 md:grid-cols-4 items-center ">
                <!--Titulo Design-->
                <div id="contentTitulo" class="justify-self-center col-span-3 md:col-span-8" >
                    <h1 class="poppins-bold text-2xl color-primary text-center">Páginas</h1>
                </div>
            </section>

            <!--Listado de Paginas-->
            <section id="vistaTestimonios" class="mt-6 relative">
                <div  class="grid grid-cols-2 md:grid-cols-5 md:gap-x-4 gap-8 overflow-y-auto md:justify-center">
                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Home</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/home'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                
                            </div>
                        </div>
                        
                    </div>

                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Relocation</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/relocation'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                
                            </div>
                        </div>
                        
                    </div>


                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Business</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/empresas'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                
                            </div>
                        </div>
                        
                    </div>

                     <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Legal Services</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/areas-practica'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                
                            </div>
                        </div>
                        
                    </div>
                   
                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">About</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/nosotros'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>

                                
                            </div>
                        </div>
                        
                    </div>

                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Associate Services</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/partner'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        
                    </div>


                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Contact</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/contact'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        
                    </div>

                    <!--Footer-->
                    <div  class="border-2 rounded-xl border-color-primary py-8 w-full flex flex-col items-center text-center justify-center  md:h-36">
                        <div>   
                            <h3 class="poppins-semibold text-base text-center mb-3">Footer</h3>
                             <!--Acciones-->
                            <div class="flex flex-row gap-x-2 items-center justify-center w-full">
                                <RouterLink :to="'/design/footer'" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                    <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    </svg>
                                </RouterLink>
                                 <a  class="bg-blue-500 w-10 h-10 px-2 rounded-lg flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                                    <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                   

                    
                </div>
            </section>
        </div>


    </div>
</template>

<script>
    import router from "../../router" 
    import Menu from '../../components/Menu';
    
     export default {
         name: "Design",
        components: {Menu},
        data(){
            return{
                home:{},
            }
        },
        mounted(){
            //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
            if(sessionStorage.getItem("rjm_token")===null){
                router.push("/")
            }
          
        },
    
     }
</script>