<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
        <div class="border border-color-primary rounded-2xl px-3 md:px-6 py-6 flex flex-col gap-y-3 col-span-12 md:mt-3">
            <!--Encabezado Timeline-->
            <div class="flex flex-col items-center justify-center md:grid md:grid-cols-3">
                <h2 class="poppins-medium text-xl mb-3 md:order-2 md:justify-self-center">Sliders</h2>
                <div class="hidden">
                    <!--Crear Timeline-->
                    <div class="flex justify-start items-center">
                        <button   v-on:click="showModalSliderPartner(null)" type="button" class="bg-primary w-auto px-3 h-10 rounded-xl poppins-regular text-white text-normal" >Crear Slider</button>
                    </div>
                </div>
            </div>

            <!--Body Timelines-->
            <div class="grid gap-8 md:gap-10 mt-6 md:grid-cols-2">
                <div  v-for="sliderPartner in partnerSliders" :key="sliderPartner._id" class="grid grid-cols-6 grid-rows-2 h-40 md:h-48 border-2 border-color-primary py-3 px-3 rounded-lg">
                    <div class="col-span-2 md:col-span-3 row-span-2 w-full">
                        <img class="w-full h-full object-cover rounded-lg" :src="sliderPartner.imagen"/>
                    </div>
                    <div class="col-span-4 md:col-span-3 row-span-2 flex flex-col justify-around px-2">
                        <div class="text-center ">
                            <h1 class="poppins-semibold  text-lg leading-4">{{sliderPartner.titulo}}</h1>
                        </div>
                        
                        <!--Acciones-->
                        <div class="flex flex-row gap-x-2  justify-center">
                            <button v-on:click="showModalSliderPartner(sliderPartner)" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </svg>
                            </button>
                            
                            <button v-on:click="eliminarSliderPartner(sliderPartner)" type="button" class="bg-red-500 w-10 h-10 rounded-lg hidden items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <section id="modalSliderPartner" class=" py-8 px-4 hidden fixed  justify-center top-0 left-0 h-screen  z-50  w-screen  overlay-blur-home transition duration-500 ease-out ">
        <div class="bg-white relative h-full overflow-y-none rounded-xl w-full md:w-6/12">  
            <!--Encabezado Timeline--> 
            <div class=" py-4 px-3 absolute top-0 w-full z-50 flex flex-row items-center justify-between rounded-t-xl">
                <!--Cerrar-->
                <svg v-on:click="hideModalSliderPartner()" class="absolute right-3 top-3 z-50" id="Componente_9_13" data-name="Componente 9 – 13" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36">
                    <rect id="Rectángulo_19" data-name="Rectángulo 19" width="36" height="36" rx="18" fill="red"/>
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(0.002)" fill="#fff"/>
                </svg>
                
            </div>
            <!--Body Timeline-->
            <div class="px-5 pb-20 md:px-10 pt-10 flex flex-col md:grid md:grid-cols-2 gap-3 overflow-auto h-full">
                <!--Imagen 1-->
                <div class="w-full flex flex-col md:items-center gap-y-2  px-3 py-3 md:p-6 ">
                    <label class="poppins-medium text-base text-center md:text-xl mb-2">Foto:</label>
                    <div class="relative w-full  h-56  md:w-56 border-2 border-color-primary rounded-xl">
                        <img id="imageSliderPartnerOne"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="partnerSlider.imagen"/>
                    </div>
                    <!--Subir foto-->
                    <div class="flex justify-center items-center mt-2">
                        <button   v-on:click="clickSubirFotoSliderPartnerOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputFotoSliderPartnerOne" v-on:change="previaFotoSliderPartnerOne($event)"  type="file" class="hidden"/>
                    </div>
                </div>

                 <!--Imagen 2-->
                 <div class="w-full flex flex-col md:items-center gap-y-2  px-3 py-3 md:p-6 ">
                    <label class="poppins-medium text-base text-center md:text-xl mb-2">Foto Popup:</label>
                    <div class="relative w-full bg-gray-300  h-56  md:w-56 border-2 border-color-primary rounded-xl">
                        <img id="imageSliderPartnerTwo"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="partnerSlider.imagenPopPup"/>
                    </div>
                    <!--Subir foto-->
                    <div class="flex justify-center items-center mt-2">
                        <button   v-on:click="clickSubirFotoSliderPartnerTwo()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputFotoSliderPartnerTwo" v-on:change="previaFotoSliderPartnerTwo($event)"  type="file" class="hidden"/>
                    </div>
                </div>

                <!--Titulo Slider-->
                <div class="flex flex-col gap-y-2 md:col-span-2"> 
                    <label class="poppins-medium text-base">Titulo:</label>
                    <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="partnerSlider.titulo">
                </div>
               
                <!--Descripcion Corta Slider-->
                <div class="flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Descripción Corta:</label>
                    <ckeditor :editor="editorDescripcionSliderPartner" v-model="partnerSlider.descripcionCorta" :config="editorConfig"></ckeditor>
                </div>

                 <!--Descripcion Larga Slider-->
                 <div class="flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Descripción Larga:</label>
                    <ckeditor :editor="editorDescripcionSliderPartner" v-model="partnerSlider.descripcionLarga" :config="editorConfig"></ckeditor>
                </div>

                <!--Boton-->
                <div class="flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Botón:</label>
                    <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="partnerSlider.btn">
                </div>
               

                <div class="flex flex-col gap-y-2 md:col-span-2">
                    <div class="flex flex-row gap-x-3 mt-3">
                        <p class="poppins-medium text-base">Sub-servicios:</p>
                    </div>

                    <div class="flex items-center  gap-x-3 mt-2">
                        <div class="flex flex-col gap-y-2">
                            <input id="servicioSliderPartner" v-model="servicioPartner.texto" class="border-2 rounded-xl px-3 py-2 w-full border-color-primary">
                        </div>
                    <!--ADD service-->
                       <button id="btnAgregarServicioPartner" @click="agregarServicio()" class="poppins-light text-base border-2 border-color-primary bg-primary text-white rounded-xl p-2 flex items-center justify-center gap-x-2" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="25.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button> 
                        <!--Editar servicio-->
                        <button id="btnEditarServicioPartner" @click="editarServicio()" class="hidden poppins-light text-base border-2 border-color-primary bg-primary text-white rounded-xl p-2 items-center justify-center gap-x-2" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="25.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button> 
                    </div>
                  
                    <ul class="grid md:grid-cols-2 gap-8 mt-4">
                        <li class="poppins-light flex flex-row justify-between gap-3" v-for="(servicio, index) in partnerSlider.servicios" :key="servicio">
                            <span>{{ servicio }}</span>
                            <div class="flex flex-row gap-x-2">
                                <!--Editar servicio slider-->
                                <button @click="cargarServicio(index)" type="button">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.91 4.15002C15.2416 5.32749 15.87 6.40009 16.735 7.26507C17.5999 8.13005 18.6725 8.75843 19.85 9.09002" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                <!--Eliminar servicio-->
                                <button @click="eliminarServicio(index)" type="button" class="hidden">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </li>
                    </ul>

                   
                    
                </div>
            </div>

             <!--Footer Slider--> 
             <div class="absolute bottom-0 w-full z-50 flex bg-white flex-row items-center justify-between rounded-b-xl ">
                
                <!--Acciones Slider-->
                <div class="flex items-center justify-center  py-2 w-full">
                    <!--Acciones-->
                    <div class="flex flex-row gap-x-2  justify-center col-span-5">
                        <button v-on:click="crearEditarSliderPartner(partnerSlider)" type="button" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button>
                        <button  v-on:click="eliminarSliderPartner(partnerSlider)" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                            <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                            </svg>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </section>



</template>



<script>
   
   import axios from 'axios';
   import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
   import CKEditor from '@ckeditor/ckeditor5-vue';
   import Swal from 'sweetalert2'

   import FileServices from '../../services/fileService.js';


    export default {
        name:"PortadaPartner",
       components: {ckeditor: CKEditor.component},
        data(){
            return{
                editorDescripcion: ClassicEditor,
               editorDescripcionSliderPartner:ClassicEditor,
               editorConfig: {
                   // The configuration of the editor.
                   toolbar: {
                       items:[]
                   }
               },
                partnerSliders:[],
                partnerSlider:{
                    titulo:"",
                    descripcionCorta:"",
                    descripcionLarga:"",
                    imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    imagenPopPup: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    servicios:[],
                    estado:true
                },
                servicioPartner: {
                    indice: 0,
                    texto: ''
                },
                imgFileOneSliderPartner: '',
                APIURL: "/api"
            }

        },
        mounted(){
           this.getPartnerSliders();   
       },
        methods:{
            getPartnerSliders(){
                axios.get(this.APIURL+"/partner/sliders").then((resp) => {
                    console.log(resp)
                    this.partnerSliders=resp.data
                    this.partnerSliders.sort((a, b)=> a.titulo - b.titulo)
                })
            },

            crearEditarSliderPartner(){
                
                var formData;
                console.log(this.sliderPartner)
                //crear
                if(this.partnerSlider._id==='' || this.partnerSlider._id===undefined){
                    //subir foto del Slider
                    formData = new FormData();
                    formData.append('upload', this.imgFileFotoSliderPartnerOne)
                    //imagen 1
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            this.partnerSlider.imagen=res.location;
                        }

                         // imagen 2 
                        formData = new FormData();
                        formData.append('upload', this.imgFileFotoSliderPartnerTwo)
                        //imagen 1
                        FileServices.subirArchivos(formData).then(res=>{
                            if(res.location!=undefined){
                                this.partnerSlider.imagenPopPup=res.location;
                                console.log(this.partnerSlider)
                                
                                axios.post(this.APIURL+"/partner/sliders/crear/",this.partnerSlider)

                            }else{
                                axios.post(this.APIURL+"/partner/sliders/crear/",this.partnerSlider)
                            }
                            
                        })
                        
                    })

                   

                    Swal.fire({
                        title: "Partner Guardado con éxito",
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getPartnerSliders();
                                return
                            } 
                    });
                }else{ //editar
                    console.log(this.partnerSlider)
                    //subir foto del Slider
                    formData = new FormData();
                    formData.append('upload', this.imgFileFotoSliderPartnerOne)
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            this.partnerSlider.imagen=res.location;

                            axios.put(this.APIURL+"/partner/sliders/"+this.partnerSlider._id,this.partnerSlider)

                        }else{
                            //actualizar la relcoated
                            axios.put(this.APIURL+"/partner/sliders/"+this.partnerSlider._id,this.partnerSlider)
                        }
                        
                    })

                    //subir foto 2
                    formData = new FormData();
                    formData.append('upload', this.imgFileFotoSliderPartnerTwo)
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            this.partnerSlider.imagenPopPup=res.location;

                            axios.put(this.APIURL+"/partner/sliders/"+this.partnerSlider._id,this.partnerSlider)

                        }else{
                            //actualizar la relcoated
                            axios.put(this.APIURL+"/partner/sliders/"+this.partnerSlider._id,this.partnerSlider)
                        }
                        
                    })

                    Swal.fire({
                        title: "Partner Guardado con éxito",
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getPartnerSliders();
                                return
                            } 
                    });
                    
                }
                
                   
            },

            eliminarSliderPartner(partner){
               
                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            partner.estado = false 


                            console.log(partner)

                            axios.put(this.APIURL+"/partner/sliders/"+partner._id,partner).then((response)=>{
                                if(response.status===200){
                                    Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then(() => {
                                       
                                            this.partnerSliders=[]
                                            this.getPartnerSliders()
                                        
                                    });
                                }
                                
                            })
                            
                        }
                    });
            
            },

            clickSubirFotoSliderPartnerOne(){
                document.getElementById("inputFotoSliderPartnerOne").click()
            },
            previaFotoSliderPartnerOne(event){
                    
                const fileFoto= event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFoto);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageSliderPartnerOne").src = img;
                
                console.log(fileFoto)
                this.imgFileFotoSliderPartnerOne = fileFoto
                
            },

            clickSubirFotoSliderPartnerTwo(){
                document.getElementById("inputFotoSliderPartnerTwo").click()
            },
            previaFotoSliderPartnerTwo(event){
                    
                const fileFoto= event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFoto);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageSliderPartnerTwo").src = img;
                
                console.log(fileFoto)
                this.imgFileFotoSliderPartnerTwo = fileFoto
                
            },
             /*Mostrar el modal de crear/editar trayectorai */
             showModalSliderPartner(partnerSlider){
                document.getElementById("modalSliderPartner").classList.remove("hidden")
                document.getElementById("modalSliderPartner").classList.add("flex")
                console.log(partnerSlider)
                if(partnerSlider!=null){
                    this.partnerSlider = partnerSlider;
                    console.log(this.partnerSlider)
                }else{
                    this.partnerSlider.estado=true;
                    this.partnerSlider.imagen="https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png"
                }
                
                
            },

             /*Cerrar Modal de crear/editar trayectoria */
             hideModalSliderPartner(){
                    document.getElementById("modalSliderPartner").classList.add("hidden")
                    document.getElementById("modalSliderPartner").classList.remove("flex")
                    delete this.partnerSlider._id;
                    this.partnerSlider={
                        titulo:"",
                        descripcion:"",
                        imagen: "",
                        id_partner:"",
                        estado:true
                    }
                    this.imgFileOneSliderPartner = ""
            },

            /*Agregar servicio en slider partner */
            agregarServicio(){
                if(this.servicioPartner!==null){
                    this.partnerSlider.servicios.push(this.servicioPartner.texto)
                    this.servicioPartner.texto = ""
                }
            },

            //editar el servicio del partner
            cargarServicio(indice){
                var servicio = this.partnerSlider.servicios[indice];
                this.servicioPartner.indice = indice
                this.servicioPartner.texto = servicio
                document.getElementById("btnAgregarServicioPartner").classList.remove("flex")
                document.getElementById("btnAgregarServicioPartner").classList.add("hidden")

                document.getElementById("btnEditarServicioPartner").classList.remove("hidden")
                document.getElementById("btnEditarServicioPartner").classList.add("flex")
            },
            //editar servicio del partner slider,
            editarServicio(){
                
                this.partnerSlider.servicios[Number(this.servicioPartner.indice)]= this.servicioPartner.texto;

                this.servicioPartner={
                    indice: 0,
                    texto: ''
                }
            },
            //eliminar un servicio del partner
            eliminarServicio(indice){
                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.partnerSlider.servicios.splice(indice, 1)
                           // this.crearEditarSliderPartner();
                        }
                    })
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
