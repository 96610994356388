<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
        <!--Body-->
        <section class="main-section w-full md:grid md:grid-cols-12 ml-4 mr-4 md:ml-80  md:mr-6 ">
            <!--Submenu Diseño-->
            <div class="hidden md:flex md:flex-col bg-primary md:col-span-2 md:mt-28 rounded-l-2xl py-12" style="min-height: 82vh">
                <div class="w-full px-3">
                    <h2 class="poppins-regular text-white text-xl text-center">Secciones</h2>
                </div>
                <ul class="mt-12 w-full">
                    <li v-on:click="fondoSubmenuItem('Portada')" class="w-full flex">
                        <button id="btnPortada" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white text-white w-full text-base text-left">1. Portada</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('AreaPractica')" class="w-full flex">
                        <button id="btnAreaPractica" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">2. Legal Services</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('Nosotros')" class="w-full flex">
                        <button id="btnNosotros" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">3. About</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('Relocated')" class="w-full hidden">
                        <button id="btnRelocated" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">4. Relocated</button>
                    </li>
                    <li v-on:click="fondoSubmenuItem('Empresas')" class="w-full hidden">
                        <button id="btnEmpresas" type="button" class="poppins-regular px-3 py-4 border border-l-none border-white w-full text-white text-base text-left">5. Empresas</button>
                    </li>
                </ul>
            </div>
            <form @submit.prevent="guardarHome()" class="mt-28 rounded-xl md:ml-0 md:rounded-2xl bg-white w-full  py-4 px-4 md:px-10 md:py-10 overflow-auto md:col-span-10 md:rounded-l-none" style="max-height: 84vh;">
                <!--Encabezado-->
                <section id="encabezadoMenu" class="grid grid-cols-2 md:grid-cols-12 items-center md:mb-12 gap-x-3">
                    <!--Titulo Home-->
                    <div id="contentTitulo" class="justify-self-center col-span-2 md:col-span-8 md:col-start-3" >
                        <h1 class="poppins-bold text-2xl color-primary text-center">Diseño Pages</h1>
                        <p class="poppins-regular text-center">Páginas > <span class="poppins-semibold">Home</span></p>
                    </div>

                    <!--Select submenu-->
                    <div class="col-span-1 md:hidden mt-4">
                        <select class="bg-primary rounded-xl py-2 text-white poppins-regular px-3 w-full" v-on:change="fondoSubmenuItemMobile($event)" style="max-width:150px">
                            <option value="Portada">Portada</option>
                            <option value="AreaPractica">Legal Services</option>
                            <option value="Nosotros">About</option>
                            <option value="Relocated" class="hidden">Relocated</option>
                            <option value="Empresas" class="hidden">Empresas</option>

                        </select>
                    </div>

                    <!--Acciones-->
                    <div class="flex flex-row gap-x-1   justify-end col-span-1 md:col-span-2 mt-4">
                        <button type="submit" class="bg-green-600 w-9 h-9 md:w-12 md:h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button>
                        <a  class="bg-blue-500 w-9 h-9 md:w-12 md:h-12 px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                            <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                            </svg>
                        </a>
                    </div>

                </section>

                <!--Body-->
                <section class="pt-6 md:pt-0 overflow-x-hidden overflow-y-auto grid grid-cols-1">
                    <!--Portada-->
                    <div id="Portada_home" class="">
                        <PortadaHome  :home="home" @home="getChangesHome" @imgFilePortadaOneHome="getFilePortadaOneHome" @imgFilePortadaTwoHome="getFilePortadaTwoHome" @imgFilePortadaThreeHome="getFilePortadaThreeHome"/>
                    </div>

                    <!--Area de Practica-->
                    <div id="AreaPractica_home" class="hidden">
                        <AreaPracticaHome  :home="home" @home="getChangesHome" />
                    </div>

                    <!--Nosotros-->
                    <div id="Nosotros_home" class="hidden">
                        <NosotrosHome :home="home" @home="getChangesHome" @imgFileFondoNosotros="getFileFondoSobreNosotros" />
                    </div>

                    <!--Relocated-->
                    <div id="Relocated_home" class="hidden">
                        <RelocatedHome :home="home" @home="getChangesHome" @imgFileRelocatedOne="getFileRelocatedOne" @imgFileRelocatedTwo="getFileRelocatedTwo" @imgFileRelocatedThree="getFileRelocatedThree" @imgFileRelocatedFour="getFileRelocatedFour" />
                    </div>

                    <!--Empresas-->
                    <div id="Empresas_home" class="hidden">
                        <EmpresasHome :home="home" @home="getChangesHome" />
                    </div>


                </section>
            </form>


        </section>
       
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

/*Componentes */
import Menu from '../../components/Menu';
import PortadaHome from '../../components/home/PortadaHome.vue';
import NosotrosHome from '../../components/home/NosotrosHome.vue';
import AreaPracticaHome from '../../components/home/AreaPracticaHome.vue';
import RelocatedHome from '../../components/home/RelocatedHome.vue';
import EmpresasHome from '../../components/home/EmpresasHome.vue';



/*Services */
import FileServices from '../../services/fileService.js';

export default {
    name:"home",
    components: { Menu, PortadaHome, NosotrosHome, AreaPracticaHome, RelocatedHome, EmpresasHome },
    data(){
        return {
            home:{
                portada:{
                    imagen_one:"",
                    imagen_two:"",
                    imagen_three:"",
                    titulo:"",
                    descripcion:""
                },
                area_practica:{
                    titulo:"", 
                    descripcion:"",
                    btn: "" 
                },
                nosotros:{
                    titulo:"", 
                    descripcion:"", 
                    imagen: ""
                },
                relocated:{
                    titulo:"", 
                    relocatedOne:{
                        titulo:"",
                        imagen:"",
                        descripcion:""
                    },
                    relocatedTwo:{
                        titulo:"",
                        imagen:"",
                        descripcion:""
                    },
                    relocatedThree:{
                        titulo:"",
                        imagen:"",
                        descripcion:""
                    },
                    relocatedFour:{
                        titulo:"",
                        imagen:"",
                        descripcion:""
                    }
                }
            },
            homeEditado:"",
            filePortadaOne:"",
            filePortadaTwo:"",
            filePortadaThree:"",
            itemSubmenuAnterior:"",
            itemSubmenuActual:"",
            fileFondoSobreNostros:"",
            fileRelocatedOne:"",
            fileRelocatedTwo:"",
            fileRelocatedThree:"",
            fileRelocatedFour:"",
            APIURL: "/api"
        }
    },
     mounted(){
        this.fondoSubmenuItem("Portada")
        this.getHome();
     },
     methods:{
        getHome(){
            axios.get(this.APIURL+"/home").then((resp) => {
                this.home=resp.data.Home[0]
            })
        },

        guardarHome(){
           
            if(this.home!=null){
                var formData;
                var homeEditado  = this.home

                
                /*Subir Imagen One de la portada*/
                   formData = new FormData();
                    formData.append('upload',this.filePortadaOne )
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            homeEditado.portada.imagen_one=res.location;
                            console.log(homeEditado)
                            
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)  
                            })
                        }
                        
                    })

                    /*Subir Imagen tWO de la portada*/
                   formData = new FormData();
                    formData.append('upload',this.filePortadaTwo )
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            console.log(res.location)
                            homeEditado.portada.imagen_two=res.location;
                            console.log(homeEditado)
                            
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)  
                            })
                        }
                        
                    })

                    /*Subir Imagen tWO de la portada*/
                   formData = new FormData();
                    formData.append('upload',this.filePortadaThree )
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            homeEditado.portada.imagen_three=res.location;
                            console.log(homeEditado)
                            
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)  
                            })
                        }
                        
                    })



                    /*Subir Imagen One de la portada*/
                   formData = new FormData();
                    formData.append('upload',this.filePortadaOne )
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            homeEditado.portada.imagen_one=res.location;
                            console.log(homeEditado)
                            
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)  
                            })
                        }
                        
                    })

                    /*Subir imagen fondo de sobre nosotros */
                    formData = new FormData();
                    console.log(this.fileFondoSobreNostros)
                    formData.append('upload',this.fileFondoSobreNostros )
                    FileServices.subirArchivos(formData).then(res=>{
                        console.log(res.location)
                        if(res.location!=undefined){
                            homeEditado.nosotros.imagen=res.location;
                            console.log(homeEditado)
                    
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }
                    })

                     /*Subir imagen Relocated One */
                    formData = new FormData();
                    console.log(this.fileRelocatedOne)
                    formData.append('upload',this.fileRelocatedOne )
                    FileServices.subirArchivos(formData).then(res=>{
                        console.log(res.location)
                        if(res.location!=undefined){
                            homeEditado.relocated.relocatedOne.imagen=res.location;
                            console.log(homeEditado)
                    
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }
                    })

                     /*Subir imagen Relocated Two */
                     formData = new FormData();
                    console.log(this.fileRelocatedTwo)
                    formData.append('upload',this.fileRelocatedTwo )
                    FileServices.subirArchivos(formData).then(res=>{
                        console.log(res.location)
                        if(res.location!=undefined){
                            homeEditado.relocated.relocatedTwo.imagen=res.location;
                            console.log(homeEditado)
                    
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }
                    })

                    /*Subir imagen Relocated Three */
                    formData = new FormData();
                    console.log(this.fileRelocatedThree)
                    formData.append('upload',this.fileRelocatedThree )
                    FileServices.subirArchivos(formData).then(res=>{
                        console.log(res.location)
                        if(res.location!=undefined){
                            homeEditado.relocated.relocatedThree.imagen=res.location;
                            console.log(homeEditado)
                    
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }
                    })

                    /*Subir imagen Relocated Four */
                    formData = new FormData();
                    console.log(this.fileRelocatedFour)
                    formData.append('upload',this.fileRelocatedFour )
                    FileServices.subirArchivos(formData).then(res=>{
                        console.log(res.location)
                        if(res.location!=undefined){
                            homeEditado.relocated.relocatedFour.imagen=res.location;
                            console.log(homeEditado)
                    
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }else{
                            //actualizar la home
                            axios.put(this.APIURL+"/home/", homeEditado).then((response)=>{
                                console.log(response)
                                
                            })
                        }
                    })

                 Swal.fire({
                        title: "Home Guardado con éxito ",
                        icon: 'success',
                    })
            }else{
                 Swal.fire({
                    title: "Home Guardado con éxito",
                    icon: 'success',
                })
            }
            

        },

        getChangesHome(data){
            this.home = data
        },


        getFilePortadaOneHome(data){
            this.filePortadaOne = data;
        },
        getFilePortadaTwoHome(data){
            this.filePortadaTwo = data;
        },
        
        getFilePortadaThreeHome(data){
            this.filePortadaThree = data;
        },
        
        
        getFileFondoSobreNosotros(data){
             this.fileFondoSobreNostros = data;
        },

         
        getFileRelocatedOne(data){
             this.fileRelocatedOne = data;
        },

        getFileRelocatedTwo(data){
             this.fileRelocatedTwo = data;
        },

        getFileRelocatedThree(data){
             this.fileRelocatedThree = data;
        },

        getFileRelocatedFour(data){
             this.fileRelocatedFour = data;
        },



        fondoSubmenuItem(itemMenu){

            console.log(this.itemSubmenuAnterior)
            document.getElementById("btn"+itemMenu).classList.add("bg-white")
            document.getElementById("btn"+itemMenu).classList.remove("text-white")

            document.getElementById(itemMenu+"_home").classList.remove("hidden")

            if(this.itemSubmenuAnterior!="" && itemMenu!=this.itemSubmenuAnterior){
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.remove("bg-white")
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.add("text-white")
            document.getElementById(this.itemSubmenuAnterior+"_home").classList.add("hidden")

            }
            this.itemSubmenuAnterior = itemMenu;

        },
         fondoSubmenuItemMobile(event){
            var itemMenu = event.target.value
            console.log(this.itemSubmenuAnterior)
            document.getElementById("btn"+itemMenu).classList.add("bg-white")
            document.getElementById("btn"+itemMenu).classList.remove("text-white")

            document.getElementById(itemMenu+"_home").classList.remove("hidden")

            if(this.itemSubmenuAnterior!="" && itemMenu!=this.itemSubmenuAnterior){
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.remove("bg-white")
                document.getElementById("btn"+this.itemSubmenuAnterior).classList.add("text-white")
            document.getElementById(this.itemSubmenuAnterior+"_home").classList.add("hidden")

            }
            this.itemSubmenuAnterior = itemMenu;
            

        }
     }
}   

</script>