<template class=""> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>

        <!--BODY-->
        <div class="main-section mt-28 px-4 w-full md:ml-80 md:mt-28 md:mr-6 md:px-0 md:grid md:grid-cols-1 md:gap-y-6">
            <!--Encabezado-->
           <section id="encabezadoMenu" class="grid grid-cols-3 md:grid-cols-4 items-center  rounded-2xl bg-white w-full py-4 px-4 md:py-5 ">
                 <!--Search-->
                <div id="contentSearch" class="md:col-span-1">
                    <div class="flex flex-row">
                        <div class="hidden border-2 md:rounded-r-none border-color-primary rounded-lg md:flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>

                        <div id="btnSearch" v-on:click="showSearchMobile()" class="md:hidden border-2 md:rounded-r-none border-color-primary rounded-lg flex items-center justify-center w-12 h-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.089" height="25.004" viewBox="0 0 25.089 25.004">
                                <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M28.216,26.759l-6.674-6.737A9.511,9.511,0,1,0,20.1,21.485l6.63,6.693a1.027,1.027,0,0,0,1.45.037A1.034,1.034,0,0,0,28.216,26.759ZM14.068,21.566a7.51,7.51,0,1,1,5.312-2.2A7.464,7.464,0,0,1,14.068,21.566Z" transform="translate(-4 -3.993)" fill="#1e2d3b" stroke="#1e2d3b" stroke-width="1"/>
                            </svg>
                        </div>
                        
                        <input id="inputBuscador" v-on:keyup="buscarMensaje($event)" class="hidden md:block md:border-l-0 md:rounded-l-none text-gray-400 border-2 border-color-primary rounded-lg h-12 w-full px-3" placeholder="Buscar Correo Electrónico"/>
                    </div>
                </div>
                <!--Titulo Mensajes-->
                <div id="contentTitulo" class="justify-self-center md:col-span-2">
                    <h1 class="poppins-bold text-xl color-primary text-center">Mensajes</h1>
                </div>
            </section>
            
            <section class="md:grid md:grid-cols-3 md:gap-x-6 mt-6 md:mt-0">
                
                 <!--Listado de Mensajes / Detalle Mensaje-->
                <div class="md:col-span-1 overflow-auto md:pr-3" style="max-height:70vh;min-height:70vh;">
                    <!--Listado Mensaje-->
                    <div class="grid grid-cols-1 gap-y-3">
                        <div  v-for="mensaje in mensajes" :key="mensaje._id" :id="'mensaje-'+mensaje._id" v-on:click="showModalMensaje(mensaje._id)" class="bg-white h-30 shadow-xl rounded-xl px-4 py-6 grid grid-cols-4">
                            <div  class="flex flex-col gap-x-4 col-span-4">
                                <div class="flex flex-row justify-between w-full gap-x-4">
                                    <p class="poppins-medium text-lg leading-none">{{mensaje.nombre}}</p>
                                    <p class="poppins-regular text-xs" v-if="mensaje.fecha != moment(moment(new Date())).format('DD/MM/YYYY')" >{{mensaje.fecha}} {{moment(Number(mensaje.time)).format("H:mm")}}</p>
                                    <p class="poppins-regular text-xs" v-if="mensaje.fecha=== moment(moment(new Date())).format('DD/MM/YYYY')" >Hoy a las {{moment(Number(mensaje.time)).format("H:mm")}}</p>

                                </div>
                                <p class="poppins-medium text-xs mb-1 mt-1 w-full">{{mensaje.correoCliente}}</p>
                            </div> 
                            <!--
                            <div class="relative justify-self-end">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="5.344" viewBox="0 0 27 5.344">
                                    <g id="Icon_ionic-ios-more" data-name="Icon ionic-ios-more" transform="translate(-4.5 -15.328)">
                                        <path id="Trazado_48" data-name="Trazado 48" d="M17.986,15.328A2.672,2.672,0,1,0,20.658,18a2.671,2.671,0,0,0-2.672-2.672Z"/>
                                        <path id="Trazado_49" data-name="Trazado 49" d="M7.172,15.328A2.672,2.672,0,1,0,9.844,18a2.671,2.671,0,0,0-2.672-2.672Z"/>
                                        <path id="Trazado_50" data-name="Trazado 50" d="M28.828,15.328A2.672,2.672,0,1,0,31.5,18a2.671,2.671,0,0,0-2.672-2.672Z"/>
                                    </g>
                                </svg>

                                Submenu
                                <div class="hidden absolute right-0 top-3 border px-3 py-3 w-30 bg-primary rounded-lg">
                                    <div class="flex flex-row gap-x-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 21 27">
                                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9ZM28.5,6H23.25l-1.5-1.5h-7.5L12.75,6H7.5V9h21Z" transform="translate(-7.5 -4.5)" fill="red"/>
                                        </svg>
                                        <p class="poppins-regular text-white ">Eliminar</p>

                                    </div>
                                </div>
                            </div>
                                -->
                            <div class="col-span-4 ">
                                <p class="poppins-regular text-xs  whitespace-wrap overflow-hidden h-8">{{mensaje.mensaje}}</p>
                            </div>
                            
                        </div>
                    
                    </div>
                    <!--------------------------------
                    -         Paginacion
                    ---------------------------------->
                    <section class="mt-12 flex flex-row gap-2 justify-center  bottom-12 left-0 md:left-80 right-0 pb-12">
                        <span v-on:click="prevPage()" class="border border-black p-2 flex items-center justify-center rounded-lg w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="16.227" viewBox="0 0 9.277 16.227">
                            <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.047,14.3l6.14-6.135a1.16,1.16,0,1,0-1.642-1.638l-6.956,6.951a1.157,1.157,0,0,0-.034,1.6l6.985,7a1.16,1.16,0,0,0,1.642-1.638Z" transform="translate(-11.251 -6.194)"/>
                            </svg>
                        </span>

                        <ul class="flex flex-row gap-2">
                            <li class=" p-2 flex items-center justify-center rounded-lg w-8 h-8" :class="(page === paginacion.page) ? 'bg-primary text-white': 'border border-color-primary'">{{page}}</li>
                        </ul>

                        <span  v-on:click="nextPage()" class="border border-black p-2 flex items-center justify-center rounded-lg w-8 h-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.28" height="16.23" viewBox="0 0 9.28 16.23">
                            <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M17.729,14.308,11.587,8.171a1.155,1.155,0,0,1,0-1.638,1.17,1.17,0,0,1,1.643,0l6.958,6.953a1.158,1.158,0,0,1,.034,1.6l-6.987,7a1.16,1.16,0,1,1-1.643-1.638Z" transform="translate(-11.246 -6.196)"/>
                            </svg>
                        </span>
                    </section>

                    
                </div>
                <!--Detalle del mensaje-->
                <div class="hidden md:block bg-white relative  overflow-auto rounded-xl w-full col-span-2"  style="max-height:70vh;min-height:70vh;">  
                    <!--Encabezado Mensaje--> 
                    <div class="bg-primary py-4 px-3 absolute top-0 w-full flex flex-row items-center justify-between rounded-t-xl">
                        <h2 class="text-white text-lg poppins-medium">{{mensaje.nombre}}</h2>
                        <!--Email - Teleono - Delte -->
                        <div class="flex flex-row gap-x-2">
                            <!--Email-->
                            <a :href="'mailto:'+mensaje.correoCliente" class="bg-white rounded-lg w-10 h-10 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.157" height="23.126" viewBox="0 0 25.157 20.126">
                                <path id="Icon_material-email" data-name="Icon material-email" d="M25.642,6H5.516a2.512,2.512,0,0,0-2.5,2.516L3,23.61a2.523,2.523,0,0,0,2.516,2.516H25.642a2.523,2.523,0,0,0,2.516-2.516V8.516A2.523,2.523,0,0,0,25.642,6Zm0,5.031L15.579,17.321,5.516,11.031V8.516l10.063,6.289L25.642,8.516Z" transform="translate(-3 -6)" fill="#1e2d3b"/>
                                </svg>
                            </a>
                            <!--Telefono
                            <button class="border-2 border-white rounded-lg w-10 h-10 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.701" height="20.747" viewBox="0 0 17.701 25.747">
                                <path id="Icon_metro-mobile" data-name="Icon metro-mobile" d="M22.519,1.928H8.036A1.614,1.614,0,0,0,6.427,3.537V26.066a1.614,1.614,0,0,0,1.609,1.609H22.519a1.614,1.614,0,0,0,1.609-1.609V3.537A1.614,1.614,0,0,0,22.519,1.928ZM15.277,26.513a1.252,1.252,0,1,1,1.252-1.252A1.252,1.252,0,0,1,15.277,26.513Zm7.241-3.665H8.036V5.146H22.519Z" transform="translate(-6.427 -1.928)" fill="#fff"/>
                                </svg>
                            </button>
                            -->
                            <button v-on:click="eliminarMensaje(mensaje._id)" class="bg-white rounded-lg w-10 h-10 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.906" height="20.451" viewBox="0 0 15.906 20.451">
                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.636,22.679a2.279,2.279,0,0,0,2.272,2.272H20a2.279,2.279,0,0,0,2.272-2.272V9.045H8.636ZM23.406,5.636H19.43L18.294,4.5H12.613L11.477,5.636H7.5V7.909H23.406Z" transform="translate(-7.5 -4.5)" fill="red"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <!--Body mensaje-->
                    <div class="px-5 pb-5 pt-24 overflow-auto h-full">
                        <div class="grid grid-cols-3 poppins-regular text-gray-400 text-sm gap-1">
                            <p class="flex flex-row items-center gap-x-2 text-start col-span-2">{{mensaje.correoCliente}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.046" height="17.195" viewBox="0 0 15.046 17.195">
                                <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M10.747,15.046v1.343a.806.806,0,0,1-.806.806H.806A.806.806,0,0,1,0,16.389V4.03a.806.806,0,0,1,.806-.806H3.224v9.941A1.883,1.883,0,0,0,5.1,15.046Zm0-11.553V0H5.1A.806.806,0,0,0,4.3.806V13.165a.806.806,0,0,0,.806.806H14.24a.806.806,0,0,0,.806-.806V4.3H11.553A.808.808,0,0,1,10.747,3.493ZM14.81,2.451,12.6.236A.806.806,0,0,0,12.025,0h-.2V3.224h3.224v-.2a.806.806,0,0,0-.236-.57Z" fill="#1e2d3b"/>
                                </svg>
                            </p>
                            <p class="justify-self-end">{{mensaje.fecha}} {{mensaje.time}}</p>
                            <p class="flex flex-row items-center gap-x-2 text-start col-span-2">{{mensaje.telefono}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.046" height="17.195" viewBox="0 0 15.046 17.195">
                                <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M10.747,15.046v1.343a.806.806,0,0,1-.806.806H.806A.806.806,0,0,1,0,16.389V4.03a.806.806,0,0,1,.806-.806H3.224v9.941A1.883,1.883,0,0,0,5.1,15.046Zm0-11.553V0H5.1A.806.806,0,0,0,4.3.806V13.165a.806.806,0,0,0,.806.806H14.24a.806.806,0,0,0,.806-.806V4.3H11.553A.808.808,0,0,1,10.747,3.493ZM14.81,2.451,12.6.236A.806.806,0,0,0,12.025,0h-.2V3.224h3.224v-.2a.806.806,0,0,0-.236-.57Z" fill="#1e2d3b"/>
                                </svg>
                            </p>
                        </div>
                        <!--Mensaje-->
                        <div class="mt-6">
                            <!--Asunto-->
                            <h3 class="poppins-regular text-xl">{{mensaje.asunto}}</h3>
                            <p class="poppins-medium text-base">Área de Práctica: <span>{{mensaje.area}}</span></p>
                            <p class="firansans-medium text-base mt-3">
                                Mensaje: <br>
                                {{mensaje.mensaje}}
                            </p>
                        
                        </div>
                    </div>
                </div>
            </section>
           

            
        </div>

    </div>
    <!--Modal Detalle de Mensaje-->
    <section id="detalleModalMensaje" class=" py-8 px-4 fixed  hidden md:hidden md:static justify-center top-0 h-screen  z-50  w-screen  overlay-blur-home transition duration-500 ease-out ">
        <div class="bg-white relative  overflow-y-none rounded-xl w-full">  
            <!--Encabezado Mensaje--> 
            <div class="bg-primary py-4 px-3 absolute top-0 w-full z-50 flex flex-row items-center justify-between rounded-t-xl">
                <!--Cerrar-->
                <svg v-on:click="hideModalMensaje()" class="absolute -right-3 -top-3 z-50" id="Componente_9_13" data-name="Componente 9 – 13" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36">
                    <rect id="Rectángulo_19" data-name="Rectángulo 19" width="36" height="36" rx="18" fill="red"/>
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(0.002)" fill="#fff"/>
                </svg>
                <h2 class="text-white text-lg poppins-medium">{{mensaje.nombreCompleto}}</h2>
                <!--Email - Teleono - Delte -->
                <div class="flex flex-row gap-x-2">
                    <!--Email-->
                    <a :href="'mailto:'+mensaje.correoCliente" class="bg-white rounded-lg w-10 h-10 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.157" height="23.126" viewBox="0 0 25.157 20.126">
                        <path id="Icon_material-email" data-name="Icon material-email" d="M25.642,6H5.516a2.512,2.512,0,0,0-2.5,2.516L3,23.61a2.523,2.523,0,0,0,2.516,2.516H25.642a2.523,2.523,0,0,0,2.516-2.516V8.516A2.523,2.523,0,0,0,25.642,6Zm0,5.031L15.579,17.321,5.516,11.031V8.516l10.063,6.289L25.642,8.516Z" transform="translate(-3 -6)" fill="#1e2d3b"/>
                        </svg>
                    </a>
                    <!--Telefono
                    <button class="border-2 border-white rounded-lg w-10 h-10 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.701" height="20.747" viewBox="0 0 17.701 25.747">
                        <path id="Icon_metro-mobile" data-name="Icon metro-mobile" d="M22.519,1.928H8.036A1.614,1.614,0,0,0,6.427,3.537V26.066a1.614,1.614,0,0,0,1.609,1.609H22.519a1.614,1.614,0,0,0,1.609-1.609V3.537A1.614,1.614,0,0,0,22.519,1.928ZM15.277,26.513a1.252,1.252,0,1,1,1.252-1.252A1.252,1.252,0,0,1,15.277,26.513Zm7.241-3.665H8.036V5.146H22.519Z" transform="translate(-6.427 -1.928)" fill="#fff"/>
                        </svg>
                    </button>
                    -->
                    <button v-on:click="eliminarMensaje(mensaje._id)" class="bg-white rounded-lg w-10 h-10 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.906" height="20.451" viewBox="0 0 15.906 20.451">
                            <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.636,22.679a2.279,2.279,0,0,0,2.272,2.272H20a2.279,2.279,0,0,0,2.272-2.272V9.045H8.636ZM23.406,5.636H19.43L18.294,4.5H12.613L11.477,5.636H7.5V7.909H23.406Z" transform="translate(-7.5 -4.5)" fill="red"/>
                        </svg>
                    </button>
                </div>
            </div>
            <!--Body mensaje-->
            <div class="px-5 pb-5 pt-24 overflow-auto h-full">
                <div class="grid grid-cols-3 poppins-regular text-gray-400 text-sm gap-1">
                    <p class="flex flex-row items-center gap-x-2 text-start col-span-2">{{mensaje.correoCliente}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.046" height="17.195" viewBox="0 0 15.046 17.195">
                        <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M10.747,15.046v1.343a.806.806,0,0,1-.806.806H.806A.806.806,0,0,1,0,16.389V4.03a.806.806,0,0,1,.806-.806H3.224v9.941A1.883,1.883,0,0,0,5.1,15.046Zm0-11.553V0H5.1A.806.806,0,0,0,4.3.806V13.165a.806.806,0,0,0,.806.806H14.24a.806.806,0,0,0,.806-.806V4.3H11.553A.808.808,0,0,1,10.747,3.493ZM14.81,2.451,12.6.236A.806.806,0,0,0,12.025,0h-.2V3.224h3.224v-.2a.806.806,0,0,0-.236-.57Z" fill="#1e2d3b"/>
                        </svg>
                    </p>
                    <p class="justify-self-end">{{mensaje.fecha}} {{mensaje.time}}</p>
                    <p class="flex flex-row items-center gap-x-2 text-start col-span-2">{{mensaje.telefono}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.046" height="17.195" viewBox="0 0 15.046 17.195">
                        <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M10.747,15.046v1.343a.806.806,0,0,1-.806.806H.806A.806.806,0,0,1,0,16.389V4.03a.806.806,0,0,1,.806-.806H3.224v9.941A1.883,1.883,0,0,0,5.1,15.046Zm0-11.553V0H5.1A.806.806,0,0,0,4.3.806V13.165a.806.806,0,0,0,.806.806H14.24a.806.806,0,0,0,.806-.806V4.3H11.553A.808.808,0,0,1,10.747,3.493ZM14.81,2.451,12.6.236A.806.806,0,0,0,12.025,0h-.2V3.224h3.224v-.2a.806.806,0,0,0-.236-.57Z" fill="#1e2d3b"/>
                        </svg>
                    </p>
                </div>
                <!--Mensaje-->
                <div class="mt-6">
                    <!--Asunto-->
                    <h3 class="poppins-regular text-xl">{{mensaje.asunto}}</h3>
                    <p class="firansans-regular text-base">
                        {{mensaje.mensaje}}
                    </p>
                   
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import router from "../../router"  
    import moment from 'moment'
    import Swal from 'sweetalert2' 

    import Menu from '../../components/Menu';
    export default {
            name: "Mensajes",
            components: {Menu},
            data(){
                return {
                    mensajes:[],
                    mensaje:'',
                    paginacion:'',
                    page:1,
                    anteriorMensaje:'',
                    moment: moment
                }
            },
            mounted(){
                //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
                if(sessionStorage.getItem("rjm_token")===null){
                    router.push("/")
                }
                    this.getMensajesForPage();
                },
            methods:{
                getMensajesForPage(){
                    axios.get("/api/message/page/"+this.page, { headers: {'x-access-token': sessionStorage.getItem("rjm_token")} }).then((response) => {
                        console.log(response)
                        this.mensajes = response.data.messages.docs
                        this.paginacion = response.data.messages

                         axios.get("/api/message/id/"+this.mensajes[0]._id, { headers: {'x-access-token': sessionStorage.getItem("rjm_token")} }).then((response) => {
                            
                            this.mensaje = response.data.Mensaje[0]
                            this.anteriorMensaje = this.mensaje._id
                            /*Poner el tiempo mileseconds to Time normal */
                            this.mensaje.time = moment(Number(response.data.Mensaje[0].time)).format("H:mm")

                            if(window.screen.availWidth>=768){
                                document.getElementById("mensaje-"+this.mensaje._id).classList.remove("bg-white")
                                document.getElementById("mensaje-"+this.mensaje._id).classList.add("bg-primary")
                                document.getElementById("mensaje-"+this.mensaje._id).classList.add("text-white")
                            }
                        })
                    })

                    

                    
                },
                /*Mostrar el modal de detalle del mensaje */
                showModalMensaje(mensaje_id){
                    document.getElementById("detalleModalMensaje").classList.remove("hidden")
                    document.getElementById("detalleModalMensaje").classList.add("flex")

                    if(window.screen.availWidth>=768){
                        document.getElementById("mensaje-"+mensaje_id).classList.remove("bg-white")
                        document.getElementById("mensaje-"+mensaje_id).classList.add("bg-primary")
                        document.getElementById("mensaje-"+mensaje_id).classList.add("text-white")
                        
                        if(this.anteriorMensaje!=''){
                            document.getElementById("mensaje-"+this.anteriorMensaje).classList.add("bg-white")
                            document.getElementById("mensaje-"+this.anteriorMensaje).classList.remove("bg-primary")
                            document.getElementById("mensaje-"+this.anteriorMensaje).classList.remove("text-white")
                        }

                        this.anteriorMensaje = mensaje_id;
                    }
                    

              
                    
                    axios.get("/api/message/id/"+mensaje_id, { headers: {'x-access-token': sessionStorage.getItem("rjm_token")} }).then((response) => {
                        
                        this.mensaje = response.data.Mensaje[0]
                          /*Poner el tiempo mileseconds to Time normal */
                        this.mensaje.time = moment(Number(response.data.Mensaje[0].time)).format("H:mm")
                    })
                },
                /*Buscar Mensaje */
                buscarMensaje(event){
                    console.log(event.target.value)
                    this.mensajes = []
                    this.page = 1;
                    if(event.target.value!=''){
                        axios.get("/api/message/search/"+event.target.value+"/page/"+this.page).then((response) => {
                            this.mensajes = response.data.messages.docs
                            this.paginacion = response.data.messages
                        })
                    }else{
                        this.mensajes = []
                        this.getMensajesForPage();
                    }
                
           
                },
                /*Abrir buscador en movil*/
                showSearchMobile(){
                    var btnSearch = document.getElementById("btnSearch");
                    var inputBuscador = document.getElementById("inputBuscador")
                    var encabezadoMenu = document.getElementById("encabezadoMenu");
                    var contentSearch = document.getElementById("contentSearch");
                    var contentTitulo = document.getElementById("contentTitulo");

                    if(this.valueSearch==false){
                        encabezadoMenu.classList.add("grid-cols-6", "gap-x-10");
                        contentSearch.classList.add("col-span-5");
                        contentTitulo.classList.add("hidden");
                        inputBuscador.classList.remove("hidden");
                        btnSearch.classList.add("rounded-r-none", "border-r-0")
                        inputBuscador.classList.add("rounded-l-none", "border-l-0")
                        this.valueSearch=true
                    }else{
                        encabezadoMenu.classList.remove("grid-cols-6", "gap-x-10");
                        contentSearch.classList.remove("col-span-5");
                        contentTitulo.classList.remove("hidden");
                        inputBuscador.classList.add("hidden");
                        btnSearch.classList.remove("rounded-r-none", "border-r-0")
                        inputBuscador.classList.remove("rounded-l-none", "border-l-0")
                        this.valueSearch=false
                    }

                },
                /*Eliminar Mensaje */
                eliminarMensaje(mensaje_id){
                     Swal.fire({
                            title: '¿Seguro quieres eliminar el mensaje?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Si',
                            cancelButtonText: 'Cancelar',

                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.get("/api/message/id/"+mensaje_id, { headers: {'x-access-token': sessionStorage.getItem("rjm_token")} }).then((response) => {
                                this.mensaje = response.data.Mensaje[0]
                                this.mensaje.estado = false;
                                
                                axios.put("/api/message/id/"+mensaje_id, this.mensaje, { headers: {'x-access-token': sessionStorage.getItem("rjm_token")} }).then(() => {
                                     Swal.fire({
                                        title: 'El mensaje se eliminó exitosamente',
                                        icon: 'success',
                                    
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.getMensajesForPage();
                                            document.getElementById("detalleModalMensaje").classList.add("hidden")
                                            document.getElementById("detalleModalMensaje").classList.remove("flex")
                                            this.mensaje={};
                                        } 
                                    });
                                })
                                
                            })
                        } 
                    });
                },
                 prevPage(){
                    if(this.page>1){
                        this.page = this.page - 1;
                        this.getMensajesForPage();
                    }
                },
                nextPage(){
                    if(this.page < this.paginacion.totalPages){
                        this.page = this.page + 1;
                        this.getMensajesForPage();
                    }
                },
                

                /*Cerrar Modal mensaje */
                hideModalMensaje(){
                    document.getElementById("detalleModalMensaje").classList.add("hidden")
                    document.getElementById("detalleModalMensaje").classList.remove("flex")


                    this.mensaje={};
                }
            }
    }
</script>
