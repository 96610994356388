<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
        <div class="border border-color-primary rounded-2xl px-3 md:px-6 py-6 flex flex-col gap-y-3 col-span-12 md:mt-3">
            <!--Encabezado Timeline-->
            <div class="flex flex-col items-center justify-center md:grid md:grid-cols-3">
                <h2 class="poppins-medium text-xl mb-3 md:order-2 md:justify-self-center">Sliders</h2>
                <div>
                    <!--Crear Timeline-->
                    <div class="flex justify-start items-center">
                        <button   v-on:click="showModalSliderEmpresas(null)" type="button" class="bg-primary w-auto px-3 h-10 rounded-xl poppins-regular text-white text-normal" >Crear Slider</button>
                    </div>
                </div>
            </div>

            <!--Body Timelines-->
            <div class="grid gap-8 md:gap-10 mt-6 md:grid-cols-2">
                <div  v-for="sliderEmpresas in empresasSliders" :key="sliderEmpresas._id" class="grid grid-cols-6 grid-rows-2 h-40 md:h-48 border-2 border-color-primary py-3 px-3 rounded-lg">
                    <div class="col-span-2 md:col-span-3 row-span-2 w-full">
                        <img class="w-full h-full object-cover rounded-lg" :src="sliderEmpresas.imagen"/>
                    </div>
                    <div class="col-span-4 md:col-span-3 row-span-2 flex flex-col justify-around px-2">
                        <div class="text-center ">
                            <h1 class="poppins-semibold  text-lg leading-4">{{sliderEmpresas.titulo}}</h1>
                        </div>
                        
                        <!--Acciones-->
                        <div class="flex flex-row gap-x-2  justify-center">
                            <button v-on:click="showModalSliderEmpresas(sliderEmpresas)" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </svg>
                            </button>
                            
                            <button v-on:click="eliminarSliderEmpresas(sliderEmpresas)" type="button" class="bg-red-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <section id="modalSliderEmpresas" class=" py-8 px-4 hidden fixed  justify-center top-0 left-0 h-screen  z-50  w-screen  overlay-blur-home transition duration-500 ease-out ">
        <div class="bg-white relative h-full overflow-y-none rounded-xl w-full md:w-6/12">  
            <!--Encabezado Timeline--> 
            <div class=" py-4 px-3 absolute top-0 w-full z-50 flex flex-row items-center justify-between rounded-t-xl">
                <!--Cerrar-->
                <svg v-on:click="hideModalSliderEmpresas()" class="absolute right-3 top-3 z-50" id="Componente_9_13" data-name="Componente 9 – 13" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36">
                    <rect id="Rectángulo_19" data-name="Rectángulo 19" width="36" height="36" rx="18" fill="red"/>
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(0.002)" fill="#fff"/>
                </svg>
                
            </div>
            <!--Body Timeline-->
            <div class="px-5 pb-20 md:px-10 pt-10 flex flex-col gap-y-3 overflow-auto h-full">
                <!--Imagen Timeline-->
                <div class="w-full flex flex-col md:items-center gap-y-2  px-3 py-3 md:p-6 ">
                    <label class="poppins-medium text-base text-center md:text-xl mb-2">Foto:</label>
                    <div class="relative w-full  h-56  md:w-56 border-2 border-color-primary rounded-xl">
                        <img id="imageSliderEmpresasOne"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="empresaSlider.imagen"/>
                    </div>
                    <!--Subir foto-->
                    <div class="flex justify-center items-center mt-2">
                        <button   v-on:click="clickSubirFotoSliderEmpresasOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputFotoSliderEmpresasOne" v-on:change="previaFotoSliderEmpresasOne($event)"  type="file" class="hidden"/>
                    </div>
                </div>
                <!--Year Timeline-->
                <div class="flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Titulo:</label>
                    <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="empresaSlider.titulo">
                </div>
               
                 <!--Descripcion Timeline-->
                 <div class="flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Descripción:</label>
                    <ckeditor :editor="editorDescripcionSliderEmpresas" v-model="empresaSlider.descripcion" :config="editorConfig"></ckeditor>
                </div>
            </div>

             <!--Footer Timeline--> 
             <div class="absolute bottom-0 w-full z-50 flex bg-white flex-row items-center justify-between rounded-b-xl ">
                
                <!--Acciones Timeline-->
                <div class="flex items-center justify-center  py-2 w-full">
                    <!--Acciones-->
                    <div class="flex flex-row gap-x-2  justify-center col-span-5">
                        <button v-on:click="crearEditarSliderEmpresas(empresaSlider)" type="button" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button>
                        <button  v-on:click="eliminarSliderEmpresas(empresaSlider)" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                            <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                            </svg>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </section>



</template>



<script>
   
   import axios from 'axios';
   import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
   import CKEditor from '@ckeditor/ckeditor5-vue';
   import Swal from 'sweetalert2'

   import FileServices from '../../services/fileService.js';


    export default {
        name:"PortadaEmpresas",
       components: {ckeditor: CKEditor.component},
        data(){
            return{
                editorDescripcion: ClassicEditor,
               editorDescripcionSliderEmpresas:ClassicEditor,
               editorConfig: {
                   // The configuration of the editor.
                   toolbar: {
                       items:[]
                   }
               },
                empresasSliders:[],
                empresaSlider:{
                    titulo:"",
                    descripcion:"",
                    imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    id_empresa:"635c62f52257a7aa46320a2d",
                    estado:true
                },
                imgFileFotoSliderEmpresasOne: '',
                APIURL: "/api"
            }

        },
        mounted(){
           this.getEmpresasSliders();   
       },
        methods:{
            getEmpresasSliders(){
                axios.get(this.APIURL+"/empresa/sliders").then((resp) => {
                    console.log(resp)
                    this.empresasSliders=resp.data
                    this.empresasSliders.sort((a, b)=> a.titulo - b.titulo)
                })
            },

            crearEditarSliderEmpresas(){
                
                var formData;
                console.log(this.empresaSlider)
    
                if(this.empresaSlider._id==='' || this.empresaSlider._id===undefined){
                    //subir foto del Slider
                    if(this.imgFileFotoSliderEmpresasOne!=""){
                        formData = new FormData();
                        console.log(this.imgFileFotoSliderEmpresasOne)
                        formData.append('upload', this.imgFileFotoSliderEmpresasOne)
                        FileServices.subirArchivos(formData).then(res=>{
                            if(res.location!=undefined){
                                this.empresaSlider.imagen=res.location;
                                console.log(this.empresaSlider)
                                
                                axios.post(this.APIURL+"/empresa/sliders/crear/",this.empresaSlider).then((response)=>{
                                    if(response.status===200){
                                        Swal.fire({
                                            title: response.data.message,
                                                icon: 'success',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    this.getEmpresasSliders();
                                                    return
                                                } 
                                            });
                                    }
                                })
                                return;
    
                            }
                            
                        })
                    }else{
                    
                        axios.post(this.APIURL+"/empresa/sliders/crear/",this.empresaSlider).then((response)=>{
                            if(response.status===200){
                                Swal.fire({
                                    title: response.data.message,
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.getEmpresasSliders();
                                            return
                                        } 
                                    });
                            }
                        })
                        return;
                    }
                }else{
                    console.log(this.empresaSlider)
                    //subir foto del Slider
                        formData = new FormData();
                        console.log(this.imgFileFotoSliderEmpresaOne)
                        formData.append('upload', this.imgFileFotoSliderEmpresasOne)
                        FileServices.subirArchivos(formData).then(res=>{
                            if(res.location!=undefined){
                                this.empresaSlider.imagen=res.location;

                                axios.put(this.APIURL+"/empresa/sliders/"+this.empresaSlider._id,this.empresaSlider).then((response)=>{
                                    if(response.status===200){
                                        Swal.fire({
                                            title: response.data.message,
                                                icon: 'success',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    this.getEmpresasSliders();
                                                    return
                                                } 
                                            });
                                    }
                                })
                                return;
    
                            }else{
                                //actualizar la relcoated
                                axios.put(this.APIURL+"/empresa/sliders/"+this.empresaSlider._id,this.empresaSlider).then((response)=>{
                                    if(response.status===200){
                                        Swal.fire({
                                            title: response.data.message,
                                                icon: 'success',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    this.getEmpresasSliders();
                                                    return
                                                } 
                                            });
                                    }
                                })
                            }
                            
                        })
                    
                }
                
                   
            },

            eliminarSliderEmpresas(empresa){
               
                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            empresa.estado = false 


                            console.log(empresa)

                            axios.put(this.APIURL+"/empresa/sliders/"+empresa._id,empresa).then((response)=>{
                                if(response.status===200){
                                    Swal.fire({
                                        title: "Eliminado Exitosamente",
                                        icon: 'success',
                                    }).then(() => {
                                        this.empresaSlider = {
                                            titulo:"",
                                            descripcion:"",
                                            imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                                            id_empresa:"635c62f52257a7aa46320a2d",
                                            estado:true
                                        },
                                        this.getEmpresasSliders()
                                        
                                    });
                                }
                                
                            })
                            
                        }
                    });
            
            },

            clickSubirFotoSliderEmpresasOne(){
                document.getElementById("inputFotoSliderEmpresasOne").click()
            },
            previaFotoSliderEmpresasOne(event){
                    
                const fileFoto= event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFoto);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageSliderEmpresasOne").src = img;
                
                console.log(fileFoto)
                this.imgFileFotoSliderEmpresasOne = fileFoto
                
            },
             /*Mostrar el modal de crear/editar trayectorai */
             showModalSliderEmpresas(empresaSlider){
                document.getElementById("modalSliderEmpresas").classList.remove("hidden")
                document.getElementById("modalSliderEmpresas").classList.add("flex")
                console.log(empresaSlider)
                if(empresaSlider!=null){
                    this.empresaSlider = empresaSlider;
                    console.log(this.empresaSlider)
                }else{
                    this.empresaSlider.estado=true;
                    this.empresaSlider.imagen="https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png"
                }
                
                
            },

             /*Cerrar Modal de crear/editar trayectoria */
             hideModalSliderEmpresas(){
                    document.getElementById("modalSliderEmpresas").classList.add("hidden")
                    document.getElementById("modalSliderEmpresas").classList.remove("flex")
                    delete this.empresaSlider._id;
                    this.empresaSlider = {
                        titulo:"",
                        descripcion:"",
                        imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                        id_empresa:"635c62f52257a7aa46320a2d",
                        estado:true
                    },
                    this.imgFileOneSliderEmpresas = ""
            },
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
