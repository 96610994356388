<template class="relative"> 
    <div class="flex flex-row">
          <!--MENU-->
        <Menu/>
        
        <!--BODY-->
        <form @submit.prevent="editarArea()" class="main-section ml-4 mr-4   mt-28 rounded-2xl bg-white w-full md:ml-80 md:mt-28 md:mr-6  py-4 px-4 md:px-10 md:py-10  overflow-auto" style="max-height: 82vh">
            <!--Encabezado-->
           <section id="encabezadoMenu" class="grid grid-cols-1 md:grid-cols-12 items-center md:mb-12">
                <!--Atras-->
                <RouterLink :to="'/area-practica'"  class="hidden md:flex col-span-2 bg-primary rounded-xl h-12 items-center justify-center gap-x-3 md:w-32">
                   <svg xmlns="http://www.w3.org/2000/svg" width="12.217" height="17.767" viewBox="0 0 12.217 17.767">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.934,15.075,23.02,8.358a1.12,1.12,0,0,0,0-1.793,1.762,1.762,0,0,0-2.163,0L11.7,14.176a1.119,1.119,0,0,0-.045,1.751l9.2,7.664a1.765,1.765,0,0,0,2.163,0,1.12,1.12,0,0,0,0-1.793Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                    </svg>
                    <span class="poppins-regular text-base text-white">Regresar</span>
                </RouterLink>  
                
                <!--Titulo Portafolio-->
                <div id="contentTitulo" class="justify-self-center md:col-span-8" >
                    <h1 class="poppins-bold text-2xl color-primary text-center">Áreas de Prácticas</h1>
                    <p class="poppins-regular text-center">Áreas de Práctica > <span class="poppins-semibold">{{area.nombre}}</span></p>
                </div>

                <!--Acciones-->
                <div class="hidden md:flex flex-row gap-x-2  justify-end col-span-2">
                    <button type="submit" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                        <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                        </svg>
                    </button>
                    <a  class="bg-blue-500 w-12 h-12 px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                        <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                        </svg>
                    </a>
                    <button v-on:click="eliminarArea(area)" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                        </svg>
                    </button>
                </div>

            </section>

            <!--Acciones en Mobile-->
            <section class="md:hidden grid grid-cols-8 mt-8 mb-9">
                 <RouterLink :to="'/Portafolio'" class=" md:hidden col-span-3 bg-primary rounded-xl h-12 flex items-center justify-center gap-x-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="12.217" height="17.767" viewBox="0 0 12.217 17.767">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.934,15.075,23.02,8.358a1.12,1.12,0,0,0,0-1.793,1.762,1.762,0,0,0-2.163,0L11.7,14.176a1.119,1.119,0,0,0-.045,1.751l9.2,7.664a1.765,1.765,0,0,0,2.163,0,1.12,1.12,0,0,0,0-1.793Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                    </svg>
                    <span class="poppins-regular text-base text-white">Regresar</span>
                </RouterLink>  
                
                <!--Acciones-->
                <div class="flex flex-row gap-x-2  justify-end col-span-5">
                    <button type="submit" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                        <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                        </svg>
                    </button>

                    <a  class="bg-blue-500 w-12 h-12 px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.322" height="24.881" viewBox="0 0 37.322 24.881">
                        <path id="Icon_awesome-eye" data-name="Icon awesome-eye" d="M37.1,15.995A20.782,20.782,0,0,0,18.661,4.5,20.785,20.785,0,0,0,.225,16a2.1,2.1,0,0,0,0,1.891A20.782,20.782,0,0,0,18.661,29.381,20.785,20.785,0,0,0,37.1,17.886,2.1,2.1,0,0,0,37.1,15.995ZM18.661,26.271a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,18.661,26.271Zm0-15.551a6.176,6.176,0,0,0-1.64.246A3.1,3.1,0,0,1,12.686,15.3a6.206,6.206,0,1,0,5.975-4.58Z" transform="translate(0 -4.5)" fill="#fff"/>
                        </svg>

                    </a>
                    <button  v-on:click="eliminarArea(area)" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                        </svg>
                    </button>
                </div>
            </section>

            <!--Campos Informacion general -  sliders  -->
            <section class="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-x-6">
                 <!--Estado y tipo de diseño-->
                <div class="md:col-span-1  flex flex-col gap-y-6 md:order-3">
                    <!--Estado-->
                    <div class="border border-color-primary rounded-2xl px-3 py-6 md:col-span-1 md:h-32">
                        <div class="w-full flex flex-col gap-y-2">
                            <label class="poppins-medium text-base">Estado:</label>
                            <select class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.borrador">
                                <option value="true">Borrador</option>
                                <option value="false">Publicado</option>
                            </select>
                        </div>
                    </div>
                    <!--Slug-->
                    <div class="border border-color-primary rounded-2xl px-3 py-6 w-full md:h-32">
                        <div class="w-full flex flex-col gap-y-2">
                            <label class="poppins-medium text-base">Slug:</label>
                            <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.slug">
                        </div>
                    </div>
                </div>

                <!--Fotos Principal y Secundaria-->
                <div class=" grid grid-cols-2 gap-x-3 md:order-1 md:col-span-1 md:flex md:flex-col md:gap-y-6">
                    <div class="w-full flex flex-col gap-y-2 border border-color-primary rounded-2xl px-3 py-3 md:p-6 ">
                        <label class="poppins-medium text-sm text-center md:text-xl mb-1">Foto Principal</label>
                        <div class="relative w-full h-32 md:h-48 border-2 border-color-primary rounded-xl">
                            <img id="imageOne"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="area.imagenPrincipal"/>
                        </div>
                       <!--Subir foto-->
                        <div class="flex justify-center items-center md:mt-3">
                            <button   v-on:click="clickSubirFotoOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                            <input id="inputFotoOne" v-on:change="previaFotoOne($event)"  type="file" class="hidden"/>
                        </div>
                    </div>

                    <div class="w-full flex flex-col gap-y-2 border border-color-primary rounded-2xl px-3 py-3 md:p-6">
                        <label class="poppins-medium text-sm text-center md:text-xl mb-1">Foto Secundaria</label>
                        <div class="relative w-full h-32 md:h-48  border-2 border-color-primary rounded-xl">
                            <img id="imageTwo"  class="w-full h-full object-cover object-center	 rounded-xl" :src="area.imagenSecundaria"/>
                        </div>
                        <!--Subir foto-->
                        <div class="flex justify-center items-center md:mt-3">
                            <button   v-on:click="clickSubirFotoTwo()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                            <input id="inputFotoTwo" v-on:change="previaFotoTwo($event)"  type="file" class="hidden"/>
                        </div>
                    </div>
                </div>

                <!--Informacion General-->
                <div class="border border-color-primary rounded-2xl px-3 py-6 flex flex-col md:grid md:grid-cols-2  gap-3 md:order-2 md:col-span-2">
                    <h2 class="poppins-medium text-xl col-span-2">Información General</h2>
                    
                    <!--Nombre del area-->
                    <div class="w-full flex flex-col gap-y-2 col-span-1">
                        <label class="poppins-medium text-base">Nombre:</label>
                        <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.nombre">
                    </div>

                    <!--Titular del area-->
                    <div class="w-full flex flex-col gap-y-2 col-span-1">
                        <label class="poppins-medium text-base">Titular sobre el Área:</label>
                        <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.titular">
                    </div>

                    
                    <!--Boton-->
                    <div class="w-full flex flex-col gap-y-2 col-span-1">
                        <label class="poppins-medium text-base">Botón:</label>
                        <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.btn">
                    </div>

                    
                    <!--Correo-->
                    <div class="w-full flex flex-col gap-y-2 col-span-1">
                        <label class="poppins-medium text-base">Correo de Contacto:</label>
                        <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="area.correo">
                    </div>
                    

                    <!--Descripcion-->
                    <div class="md:w-full  h-full col-span-2">
                        <label class="poppins-medium text-base">Descripción:</label>
                        <ckeditor :editor="editorDescripcion" v-model="area.descripcion" :config="editorConfig"></ckeditor>
                    </div>

                    
                </div>

                <!--Sliders Informativos-->
                <div class="border border-color-primary rounded-2xl px-3 md:px-6 py-6 flex flex-col gap-y-3 md:order-4 md:col-span-4 md:mt-3">
                    <!--Encabezado Slider Informativo-->
                    <div class="flex flex-col items-center justify-center md:grid md:grid-cols-3">
                        <h2 class="poppins-medium text-xl mb-3 md:order-2 md:justify-self-center">Sliders Informativos</h2>
                        <div>
                             <!--Crear Slider-->
                            <div class="flex justify-start items-center">
                                <button   v-on:click="showModalSlider(null)" type="button" class="bg-primary w-auto px-3 h-10 rounded-xl poppins-regular text-white text-normal" >Crear Slider</button>
                            </div>
                        </div>
                    </div>

                    <!--Body Slider Informativos-->
                    <div class="grid gap-8 mt-6 md:grid-cols-3">
                        
                        <div  v-for="slider in slidersInformativosArea" :key="slider" class="grid grid-cols-1 border-2 border-color-primary py-3 pb-4 px-3 rounded-lg">
                            <div class="w-full h-56">
                                <img class="w-full h-full object-cover rounded-lg " :src="slider.imagen"/>
                            </div>
                            <div class="col-span-4 row-span-2 flex flex-col justify-between">
                                <div class="text-center py-4 ">
                                    <h1 class="poppins-semibold  text-lg leading-5 truncate">{{slider.nombre}}</h1>
                                </div>
                            
                                <!--Acciones-->
                                <div class="flex flex-row gap-x-2  justify-center">
                                    <button v-on:click="showModalSlider(slider)" type="button" class="bg-yellow-200 w-10 h-10 rounded-lg flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.918" height="20.918" viewBox="0 0 23.918 23.918">
                                        <path id="Icon_feather-edit-2" data-name="Icon feather-edit-2" d="M18.824,4.131a2.984,2.984,0,0,1,4.22,4.22L8.8,22.593,3,24.176l1.582-5.8Z" transform="translate(-1.5 -1.757)" fill="none" stroke="#1e2d3b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                        </svg>
                                    </button>
                                   
                                    <button v-on:click="eliminarSlider(slider)" type="button" class="bg-red-500 w-10 h-10 rounded-lg flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20.143" viewBox="0 0 18 23.143">
                                        <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.786,25.071a2.579,2.579,0,0,0,2.571,2.571H21.643a2.579,2.579,0,0,0,2.571-2.571V9.643H8.786ZM25.5,5.786H21L19.714,4.5H13.286L12,5.786H7.5V8.357h18Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                    </div>
                    </div>
                    
                </div>
            </section>

          

           
        </form>
    </div> 
    
    <!--Modal Crear/editar Slider Informativo-->
    <section id="modalSliderInformativo" class=" py-8 px-4 hidden fixed  justify-center top-0 h-screen  z-50  w-screen  overlay-blur-home transition duration-500 ease-out ">
        <div class="bg-white relative h-full overflow-y-none rounded-xl w-full md:w-6/12">  
            <!--Encabezado slider--> 
            <div class=" py-4 px-3 absolute top-0 w-full z-50 flex flex-row items-center justify-between rounded-t-xl">
                <!--Cerrar-->
                <svg v-on:click="hideModalSlider()" class="absolute right-3 top-3 z-50" id="Componente_9_13" data-name="Componente 9 – 13" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36">
                    <rect id="Rectángulo_19" data-name="Rectángulo 19" width="36" height="36" rx="18" fill="red"/>
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(0.002)" fill="#fff"/>
                </svg>
                
            </div>
            <!--Body slider-->
            <div class="px-5 pb-20 md:px-10 pt-10 flex flex-col gap-y-3 overflow-auto h-full">
                <!--Imagen Slider-->
                <div class="w-full flex flex-col md:items-center gap-y-2  px-3 py-3 md:p-6 ">
                    <label class="poppins-medium text-base text-center md:text-xl mb-2">Foto:</label>
                    <div class="relative w-full  h-56  md:w-56 border-2 border-color-primary rounded-xl">
                        <img id="imageSliderOne"  class="w-full h-full object-cover object-center	 rounded-xl z-0" :src="sliderInformativoArea.imagen"/>
                    </div>
                    <!--Subir foto-->
                    <div class="flex justify-center items-center mt-2">
                        <button   v-on:click="clickSubirFotoSliderOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                        <input id="inputFotoSliderOne" v-on:change="previaFotoSliderOne($event)"  type="file" class="hidden"/>
                    </div>
                </div>
                <!--Nombre Slider-->
                <div class="w-full flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Nombre:</label>
                    <input class="border-2 rounded-2xl px-3 py-3 w-full border-color-primary" v-model="sliderInformativoArea.nombre">
                </div>

                 <!--Descripcion SLider-->
                 <div class="w-full flex flex-col gap-y-2">
                    <label class="poppins-medium text-base">Descripción:</label>
                    <ckeditor :editor="editorDescripcionSlider" v-model="sliderInformativoArea.descripcion" :config="editorConfig"></ckeditor>
                </div>
            </div>

             <!--Footer Slider--> 
             <div class="absolute bottom-0 w-full z-50 flex bg-white flex-row items-center justify-between rounded-b-xl ">
                
                <!--Acciones en Mobile-->
                <div class="flex items-center justify-center  py-2 w-full">
                    <!--Acciones-->
                    <div class="flex flex-row gap-x-2  justify-center col-span-5">
                        <button v-on:click="crearEditarSliderInformativo()" class="bg-green-600  w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                            <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M30.511,9.136l-5.9-5.9a3.375,3.375,0,0,0-2.386-.989H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h24.75A3.375,3.375,0,0,0,31.5,30.375V11.523a3.375,3.375,0,0,0-.989-2.386ZM15.75,29.25a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.75,29.25ZM22.5,7.838v7.068a.844.844,0,0,1-.844.844H5.344a.844.844,0,0,1-.844-.844V7.594a.844.844,0,0,1,.844-.844H21.412a.843.843,0,0,1,.6.247l.245.245a.843.843,0,0,1,.247.6Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </button>
                        <button  v-on:click="eliminarSliderInformativo(sliderInformativoArea)" type="button" class="bg-red-500 w-12 h-12  px-2 rounded-lg flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.487" viewBox="0 0 23.712 30.487">
                            <path id="Icon_material-delete" data-name="Icon material-delete" d="M9.194,31.6a3.4,3.4,0,0,0,3.387,3.387h13.55A3.4,3.4,0,0,0,29.518,31.6V11.275H9.194ZM31.212,6.194H25.284L23.59,4.5H15.122L13.428,6.194H7.5V9.581H31.212Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                            </svg>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
   
</template>

<script>
    import axios from 'axios'
    import Menu from '../../components/Menu';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import Swal from 'sweetalert2'
    const jwt = require('jsonwebtoken');



    /*Services */
    import FileServices from '../../services/fileService.js';


    export default {
        name: "EditarArea",
        components: {Menu, ckeditor: CKEditor.component},
        data(){
            return {
                editorDescripcion: ClassicEditor,
                editorDescripcionSlider: ClassicEditor,
                    editorConfig: {
                    // The configuration of the editor.
                    toolbar: {
                        items:[]
                    }
                },
                area:{
                    imagenPrincipal: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    imagenSecundaria: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    nombre:"",
                    titular:"",
                    descripcion:"",
                    btn: "",
                    correo: "",
                    borrador:false,
                    estado:true
                },
                slidersInformativosArea:[],
                sliderInformativoArea:{
                    nombre: "",
                    descripcion: "",
                    imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                    estado: true,
                    id_area:""
                },
                imgFileFotoOne:'',
                imgFileFotoTwo:'',
                imgFileFotoSliderOne:'',
                APIURL: "/api"
            }
        },
        mounted(){
            //Obtiene el token de aacceso si esta autentificado el usuario y si no se redirige al login
            /**Verificar si esta logeado en el sistema */
            if(sessionStorage.getItem("rjm_token")===null ||sessionStorage.getItem("rjm_token")==='undefined' ){
                this.$router.push({ path: '/'})
            }
            this.decoded = jwt.decode(sessionStorage.getItem("rjm_token"));
            console.log(this.decoded)
            if(this.decoded!=null){
                if(this.decoded.exp<=1){
                sessionStorage.removeItem("rjm_token");
                this.$router.push({ path: '/'})
                }
            }else{
                this.$router.push({ path: '/'})
            }
            this.getAreaID();
            
        },
        methods:{
            getAreaID(){
                var id_area = this.$route.params.id
                axios.get(this.APIURL+"/area/"+id_area).then((area) => {
                    console.log(area.data)
                    if(area.data===undefined){
                        this.$router.push("/area-practica")
                    }
                    this.area = area.data
                    this.getSlidersArea(this.area._id);
                })
            },
            getSlidersArea(id){
                this.slidersInformativosArea=[]
                axios.get(this.APIURL+"/area/sliderInformativo/"+id).then((response) => {
                   
                   this.slidersInformativosArea= response.data;
                   console.log(this.slidersInformativosArea)
               })
            },
            editarArea(){
                var formData;
                /*Publicado/borrador */
                if(this.area.borrador==="true"){
                    this.area.borrador=true;
                }else{
                    this.area.borrador=false;
                }

                //subir foto One del area
                if(this.imgFileFotoOne!=""){
                    formData = new FormData();
                    console.log(this.imgFileFotoOne)
                    formData.append('upload', this.imgFileFotoOne)
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            this.area.imagenPrincipal=res.location;
                            console.log(this.area)
                            
                            //actualizar la sobre Nosotros
                            axios.put(this.APIURL+"/area/editar/"+this.area._id, this.area, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } });
                        }
                        
                    })
                }

                //subir foto Two del area
                if(this.imgFileFotoTwo!=""){
                    formData = new FormData();
                    console.log(this.imgFileFotoTwo)
                    formData.append('upload', this.imgFileFotoTwo)
                    FileServices.subirArchivos(formData).then(res=>{
                        if(res.location!=undefined){
                            this.area.imagenSecundaria=res.location;
                            console.log(this.area)
                            
                            //actualizar la sobre Nosotros
                            axios.put(this.APIURL+"/area/editar/"+this.area._id, this.area, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } });
                        }
                        
                    })
                }else{
                    axios.put(this.APIURL+"/area/editar/"+this.area._id, this.area, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                    Swal.fire({
                            title:  response.data.message,
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getAreaID();
                            } 
                        });
                    })
                    return;
                }

                Swal.fire({
                    title: "El Área de Practica se guardo exitosamente",
                    icon: 'success',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getAreaID();
                    } 
                });
            },
            
            crearEditarSliderInformativo(){
                
                var formData;
                if(this.sliderInformativoArea._id===undefined){
                    this.sliderInformativoArea.id_area = this.area._id

                    console.log(this.sliderInformativoArea)
                    //subir foto del Slider
                    if(this.imgFileFotoSliderOne!=""){
                        formData = new FormData();
                        console.log(this.imgFileFotoSliderOne)
                        formData.append('upload', this.imgFileFotoSliderOne)
                        FileServices.subirArchivos(formData).then(res=>{
                            if(res.location!=undefined){
                                this.sliderInformativoArea.imagen=res.location;
                                console.log(this.sliderInformativoArea)
                                
                                axios.post(this.APIURL+"/area/sliderInformativo/crear/", this.sliderInformativoArea, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                                    if(response.status===200){
                                        Swal.fire({
                                            title: response.data.message,
                                                icon: 'success',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    this.getSlidersArea(this.area._id);
                                                    return
                                                } 
                                            });
                                    }
                                })
                                return;

                            }
                            
                        })
                    }else{
                    
                        axios.post(this.APIURL+"/area/sliderInformativo/crear/", this.sliderInformativoArea, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                            if(response.status===200){
                                Swal.fire({
                                    title: response.data.message,
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.getSlidersArea(this.area._id);
                                        } 
                                });
                            }
                        });
                        return;
                    }
                }else{
                    this.sliderInformativoArea.id_area = this.area._id

                    console.log(this.sliderInformativoArea)
                    //subir foto del Slider
                    if(this.imgFileFotoSliderOne!=""){
                        formData = new FormData();
                        console.log(this.imgFileFotoSliderOne)
                        formData.append('upload', this.imgFileFotoSliderOne)
                        FileServices.subirArchivos(formData).then(res=>{
                            if(res.location!=undefined){
                                this.sliderInformativoArea.imagen=res.location;
                                console.log(this.sliderInformativoArea)
                                
                                axios.put(this.APIURL+"/area/sliderInformativo/editar/"+this.sliderInformativoArea._id, this.sliderInformativoArea, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                                    if(response.status===200){
                                        Swal.fire({
                                            title: response.data.message,
                                                icon: 'success',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    this.getSlidersArea(this.area._id);
                                                    return
                                                } 
                                            });
                                    }
                                })
                                return;

                            }
                            
                        })
                    }else{
                    
                        axios.put(this.APIURL+"/area/sliderInformativo/editar/"+this.sliderInformativoArea._id, this.sliderInformativoArea, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                            if(response.status===200){
                                Swal.fire({
                                    title: response.data.message,
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.getSlidersArea(this.area._id);
                                        } 
                                });
                            }
                        });
                        return;
                    }
                }
               
            },

            eliminarSlider(slider){

                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                    if (result.isConfirmed) {
                        
                        slider.estado = false;
                        axios.put(this.APIURL+"/area/sliderInformativo/editar/"+slider._id, slider, { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                            if(response.status===200){
                                this.hideModalSlider();
                                Swal.fire({
                                    title: "Eliminado Exitosamente",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.getSlidersArea(this.area._id)
                                    } 
                                });
                            }
                        })
                    }
                });
            },

            eliminarArea(area){
                Swal.fire({
                    title: '¿Estas Seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Cancelar",
                    confirmButtonText: 'Continuar'
                    
                    }).then((result) => {
                    if (result.isConfirmed) {
                        
                        area.estado = false;
                        axios.put(this.APIURL+"/area/editar/"+area._id, area,  { headers: { 'x-access-token':sessionStorage.getItem("rjm_token") } }).then((response)=>{
                            if(response.status===200){
                                this.hideModalSlider();
                                Swal.fire({
                                    title: "Eliminado Exitosamente",
                                    icon: 'success',
                                }).then(() => {
                                   
                                        this.$router.push("/area-practica")
                                    return;
                                });
                                this.$router.push("/area-practica")
                            }
                        })
                    }
                });
            },
            
             clickSubirFotoOne(){
                document.getElementById("inputFotoOne").click()
            },

            
            previaFotoOne(event){
                
                const fileFotoOne = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFotoOne);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageOne").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(fileFotoOne)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileFotoOne = fileFotoOne
                
            },
            clickSubirFotoTwo(){
                document.getElementById("inputFotoTwo").click()
            },
            previaFotoTwo(event){
                
                const fileFotoTwo = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFotoTwo);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageTwo").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(fileFotoTwo)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileFotoTwo = fileFotoTwo
                
            },
            clickSubirFotoSliderOne(){
                document.getElementById("inputFotoSliderOne").click()
            },
            previaFotoSliderOne(event){
                
                const fileFotoSliderOne= event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(fileFotoSliderOne);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("imageSliderOne").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(fileFotoSliderOne)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileFotoSliderOne = fileFotoSliderOne
                
            },

            /*Mostrar el modal de detalle del mensaje */
            showModalSlider(slider){
                document.getElementById("modalSliderInformativo").classList.remove("hidden")
                document.getElementById("modalSliderInformativo").classList.add("flex")
                if(slider!=null){
                    this.sliderInformativoArea = slider;
                    console.log(this.sliderInformativoArea)
                }
                
                
            },

             /*Cerrar Modal mensaje */
             hideModalSlider(){
                    document.getElementById("modalSliderInformativo").classList.add("hidden")
                    document.getElementById("modalSliderInformativo").classList.remove("flex")
                    this.sliderInformativoArea={
                        nombre: "",
                        descripcion: "",
                        imagen: "https://w7.pngwing.com/pngs/848/297/png-transparent-white-graphy-color-empty-banner-blue-angle-white-thumbnail.png",
                        estado: true,
                        id_area:""
                    }
                }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 400px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 485px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
        
        
    }
</style>