<template>
    <div class="flex flex-col gap-y-3 md:gap-y-6 md:gap-x-6 md:grid md:grid-cols-12">
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <!--Campo Titulo-->
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid grid-cols-2 gap-3 md:gap-y-4  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-2">Titulo</h2> 
            <div class="flex flex-col gap-3 col-span-2" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo Sección:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.relocated.titulo" name="Valor"/>
                </div>
            </div>
        </div>
       </div>

       <!--RELOCATED ONE-->
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid md:grid-cols-12 gap-3 md:gap-6  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-12">Relocated One</h2> 
            
            <!--Campo Foto Fondo-->
             <div class="flex items-center flex-col gap-y-1 col-span-12 mb-1 md:col-span-4 row-span-3">
                <label class="poppins-medium text-base  text-left w-full">Imagen:</label>
                <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                    <img id="fondoRelocatedOne_img" class="w-full h-full object-cover border-2 border-black rounded-lg" :src="homeTemp.relocated.relocatedOne.imagen"/>
                </div>
                <!--Subir foto-->
                <div class="flex justify-center items-center md:mt-3">
                    <button   v-on:click="clickSubirFotoRelocatedOne()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                    <input id="inputImagenRelocatedOne" v-on:change="previaImageRelocatedOne($event)"  type="file" class="hidden"/>
                </div>
            </div>
            <div class="flex flex-col col-span-12 gap-3 md:gap-6 md:col-span-8" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.relocated.relocatedOne.titulo" name="Valor"/>
                </div>
                 <!--Descripcion-->
                 <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcionRelocatedOne" v-model="homeTemp.relocated.relocatedOne.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
       </div>

        <!--RELOCATED Two-->
        <div class="flex flex-col gap-y-3  md:col-span-12">
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid md:grid-cols-12 gap-3 md:gap-6  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-12">Relocated Two</h2> 
            
            <!--Campo Foto Fondo-->
             <div class="flex items-center flex-col gap-y-1 col-span-12 mb-1 md:col-span-4 row-span-3">
                <label class="poppins-medium text-base  text-left w-full">Imagen:</label>
                <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                    <img id="fondoRelocatedTwo_img" class="w-full h-full object-cover border-2 border-black rounded-lg" :src="homeTemp.relocated.relocatedTwo.imagen"/>
                </div>
                <!--Subir foto-->
                <div class="flex justify-center items-center md:mt-3">
                    <button   v-on:click="clickSubirFotoRelocatedTwo()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                    <input id="inputImagenRelocatedTwo" v-on:change="previaImageRelocatedTwo($event)"  type="file" class="hidden"/>
                </div>
            </div>
            <div class="flex flex-col col-span-12 gap-3 md:gap-6 md:col-span-8" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.relocated.relocatedTwo.titulo" name="Valor"/>
                </div>
                 <!--Descripcion-->
                 <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcionRelocatedTwo" v-model="homeTemp.relocated.relocatedTwo.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
       </div>
    
       <!--RELOCATED Three-->
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid md:grid-cols-12 gap-3 md:gap-6  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-12">Relocated Three</h2> 
            
            <!--Campo Foto Fondo-->
             <div class="flex items-center flex-col gap-y-1 col-span-12 mb-1 md:col-span-4 row-span-3">
                <label class="poppins-medium text-base  text-left w-full">Imagen:</label>
                <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                    <img id="fondoRelocatedThree_img" class="w-full h-full object-cover border-2 border-black rounded-lg" :src="homeTemp.relocated.relocatedThree.imagen"/>
                </div>
                <!--Subir foto-->
                <div class="flex justify-center items-center md:mt-3">
                    <button   v-on:click="clickSubirFotoRelocatedThree()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                    <input id="inputImagenRelocatedThree" v-on:change="previaImageRelocatedThree($event)"  type="file" class="hidden"/>
                </div>
            </div>
            <div class="flex flex-col col-span-12 gap-3 md:gap-6 md:col-span-8" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.relocated.relocatedThree.titulo" name="Valor"/>
                </div>
                 <!--Descripcion-->
                 <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcionRelocatedThree" v-model="homeTemp.relocated.relocatedThree.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
       </div>

        <!--RELOCATED Four-->
       <div class="flex flex-col gap-y-3  md:col-span-12">
         <div class="border-2 border-black rounded-lg  py-4 px-4 grid md:grid-cols-12 gap-3 md:gap-6  md:py-8  md:px-8" >
            <h2 class="poppins-medium text-lg md:text-2xl col-span-12">Relocated Four</h2> 
            
            <!--Campo Foto Fondo-->
             <div class="flex items-center flex-col gap-y-1 col-span-12 mb-1 md:col-span-4 row-span-3">
                <label class="poppins-medium text-base  text-left w-full">Imagen:</label>
                <div class=" w-full h-60 md:h-48  rounded-lg mb-2 md:mb-0">
                    <img id="fondoRelocatedFour_img" class="w-full h-full object-cover border-2 border-black rounded-lg" :src="homeTemp.relocated.relocatedFour.imagen"/>
                </div>
                <!--Subir foto-->
                <div class="flex justify-center items-center md:mt-3">
                    <button   v-on:click="clickSubirFotoRelocatedFour()" type="button" class="bg-primary w-28 h-10 rounded-xl poppins-regular text-white text-lg" >Subir</button>
                    <input id="inputImagenRelocatedFour" v-on:change="previaImageRelocatedFour($event)"  type="file" class="hidden"/>
                </div>
            </div>
            <div class="flex flex-col col-span-12 gap-3 md:gap-6 md:col-span-8" > 
                <!--Nombre-->
                <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Titulo:</label>
                    <input type="text" class="poppins-medium w-full h-12 border-2  border-black rounded-xl px-2" @input="detectInput()"  v-model="homeTemp.relocated.relocatedFour.titulo" name="Valor"/>
                </div>
                 <!--Descripcion-->
                 <div class="flex flex-col gap-y-1 md:col-span-2">
                    <label class="poppins-medium text-sm">Texto:</label>
                    <ckeditor :editor="editorDescripcionRelocatedThree" v-model="homeTemp.relocated.relocatedFour.descripcion" :config="editorConfig"  @input="detectInput()" ></ckeditor>
                </div>
            </div>
        </div>
       </div>


    </div>
</template>



<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    export default {
        name:"RelocatedHome",
        props:['home'],
        components: {ckeditor: CKEditor.component},
        data(){
            return{
                editorDescripcionRelocatedOne: ClassicEditor,
                editorDescripcionRelocatedTwo: ClassicEditor,
                editorDescripcionRelocatedThree: ClassicEditor,
                editorConfig: {
                    // The configuration of the editor.
                    toolbar: {
                        items:[]
                    }
                },
                homeTemp:{
                    relocated:{
                        titulo:"", 
                        relocatedOne:{
                            titulo:"",
                            imagen:"",
                            descripcion:""
                        },
                        relocatedTwo:{
                            titulo:"",
                            imagen:"",
                            descripcion:""
                        },
                        relocatedThree:{
                            titulo:"",
                            imagen:"",
                            descripcion:""
                        },
                        relocatedFour:{
                            titulo:"",
                            imagen:"",
                            descripcion:""
                        }
                    }
                },
                imgFileRelocatedOne: '',
                imgFileRelocatedTwo: '',
                imgFileRelocatedThree: '',


            }

        },
        methods:{
            detectInput(){
                if(this.homeTemp.relocated.titulo!=""){
                    console.log(this.homeTemp)
                    this.$emit("home", this.homeTemp);
                }
            },
            //Subir y previa de imagen de Relocated One
            clickSubirFotoRelocatedOne(){
                document.getElementById("inputImagenRelocatedOne").click()
            },
            previaImageRelocatedOne(event){
                
                const file = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(file);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("fondoRelocatedOne_img").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(file)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileRelocatedOne = file
               
               
                this.$emit("imgFileRelocatedOne", this.imgFileRelocatedOne);

                
            },
            //Subir y previa de imagen de Relocated Two
            clickSubirFotoRelocatedTwo(){
                document.getElementById("inputImagenRelocatedTwo").click()
            },
            previaImageRelocatedTwo(event){
                
                const file = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(file);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("fondoRelocatedTwo_img").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(file)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileRelocatedTwo = file
               
               
                this.$emit("imgFileRelocatedTwo", this.imgFileRelocatedTwo);

                
            },
            //Subir y previa de imagen de Relocated Three
            clickSubirFotoRelocatedThree(){
                document.getElementById("inputImagenRelocatedThree").click()
            },
            previaImageRelocatedThree(event){
                
                const file = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(file);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("fondoRelocatedThree_img").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(file)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileRelocatedThree = file
               
               
                this.$emit("imgFileRelocatedThree", this.imgFileRelocatedThree);

                
            },
            //Subir y previa de imagen de Relocated Three
            clickSubirFotoRelocatedFour(){
                document.getElementById("inputImagenRelocatedFour").click()
            },
            previaImageRelocatedFour(event){
                
                const file = event.target.files[0];
            
                //Creamos una url temporal de nuestro imagen a traves del file
                let img = URL.createObjectURL(file);
                
                //Le agregamos la url temporal en el atributo src de la etiqueta img
                document.getElementById("fondoRelocatedFour_img").src = img;
                
                /*Asignar arvhivo en objeto this.home.milestonesOne*/
                console.log(file)
               //this.home.milestonesOne.files = fileMilestonesOne;

                this.imgFileRelocatedFour = file
               
               
                this.$emit("imgFileRelocatedFour", this.imgFileRelocatedFour);

                
            }
        },
        watch: {
            home: function() {
                this.homeTemp = this.home
            }
        }
    }
</script>

<style>
    body{
        background-color:#E1E3E5
    }
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
        height: 420px;
        border: 2px solid #0e3b4f;
        border-radius: 1rem;
    }
    @media (min-width: 768px){
       .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
            height: 320px;
            border: 2px solid #0e3b4f;
            border-radius: 1rem;
        } 
    }
</style>
